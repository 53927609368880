import { Department } from "./department/Department";

export enum Role {
    SU = "SU",
    Admin = "Admin",
    User = "User"
}

export interface UserSubstitute {
    userSubstituteId: number;
    userId: number;
    substituteId: number;
    substitute: User;
    dateFrom: string;
    dateTo: string;
}

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
    isEnabled: boolean;
    phonenumber?: string;
    claims?: { [id: string]: string[] };
    twoFactorEnabled: boolean;
    departments: Omit<Department, "members" | "managers">[];
    userSubstitutes: Omit<UserSubstitute, "user">[];
}
