
import Vue from "vue";

import { Component, Prop } from "vue-property-decorator";
import CommentEditor from "@/components/Case/CommentEditor";
import { getCurrentUser, getUserFullName } from "@/services/userUtils";
import { caseModule } from "@/store/modules/case";
import { CaseMutations } from "@/store/modules/case/mutations";
import { Comment } from "@/models/case/Comment";
import { CaseActions } from "@/store/modules/case/actions";

@Component({
    components: {
        CommentEditor
    },
    methods: {
        ...caseModule.mapMutations({
            setComment: CaseMutations.SetCurrentComment
        }),
        ...caseModule.mapActions({
            deleteComment: CaseActions.DeleteComment
        }),
        getUserFullName
    }
})
export default class CommentViewWidget extends Vue {
    @Prop({ type: Object, required: true })
    private comment!: Comment;

    @Prop({ type: String, default: "100%" })
    private width!: string;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private readonly setComment!: (comment: Comment) => void;
    private readonly deleteComment!: (commentId: number) => void;
    private isDisplayMode = true;

    get isUserAuthor(): boolean {
        if (this.comment.authorId && getCurrentUser()?.id)
            return this.comment.authorId == getCurrentUser()?.id;
        else return false;
    }

    getFullDate(date: Date | null): string {
        if (date) return new Date(date).toLocaleString();
        return "No data";
    }

    changeMode(): void {
        if (this.isDisplayMode) {
            this.setComment(this.comment);
        }
        if (!this.disabled) {
            this.isDisplayMode = !this.isDisplayMode;
        }
    }

    async save(): Promise<void> {
        this.changeMode();
        await this.$emit("save");
    }

    cancel(): void {
        this.changeMode();
    }
}
