function saveBoolean(key: string, value: boolean): void {
    localStorage.setItem(key, value.toString());
}

function saveObject(key: string, object: unknown): void {
    const json = JSON.stringify(object);
    localStorage.setItem(key, json);
}

function saveProperties<K extends string>(
    key: string,
    object: Record<K, unknown>,
    properties: K[]
): void {
    const objectToSave: Record<string, unknown> & { undefined?: K[] } = {};
    const undefinedValues: K[] = [];

    for (const property of properties) {
        const propertyValue = object[property];
        if (propertyValue === undefined) {
            undefinedValues.push(property);
            continue;
        }
        objectToSave[property] = propertyValue;
    }

    if (undefinedValues.length > 0) {
        objectToSave.undefined = undefinedValues;
    }

    saveObject(key, objectToSave);
}

function loadObject<T>(key: string): T | null {
    const json = localStorage.getItem(key);
    return json !== null ? JSON.parse(json) : null;
}

function loadProperties<K extends string>(
    key: string,
    object: Record<K, unknown>,
    properties: K[]
): void {
    const loadedObject = loadObject<
        Record<string, unknown> & { undefined?: K[] }
    >(key);

    if (loadedObject === null) {
        return;
    }

    if (loadedObject.undefined) {
        loadedObject.undefined.forEach((key) => {
            loadedObject[key] = undefined;
        });
        delete loadedObject.undefined;
    }

    const objectWithProperties: Record<string, unknown> = {};
    properties.forEach((p) => (objectWithProperties[p] = loadedObject[p]));

    Object.assign(object, objectWithProperties);
}

function loadBoolean(key: string): boolean | null {
    const loadedValue = localStorage.getItem(key);
    return loadedValue === null ? null : loadedValue === "true";
}

function remove(key: string): void {
    localStorage.removeItem(key);
}

export default {
    saveBoolean,
    saveObject,
    saveProperties,
    loadBoolean,
    loadObject,
    loadProperties,
    remove
};
