
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component({})
export default class ConfirmPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: String, required: true })
    public title!: boolean;

    @Prop({ type: String, default: "Ok" })
    public confirmButtonText!: boolean;

    @Prop({ type: String, default: "Abbrechen" })
    public cancelButtonText!: boolean;

    cancel(): void {
        this.popupVisible = false;
    }

    confirm(): void {
        this.$emit("confirm");
        this.popupVisible = false;
    }
}
