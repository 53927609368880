
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import PanelLayout from "./layouts/PanelLayout";
import NonUserLayout from "./layouts/NonUserLayout";
import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store";
import { AccountActions } from "./store/modules/account/actions";

@Component({
    components: {
        MainLayout,
        AuthLayout,
        PanelLayout,
        NonUserLayout
    },
    mounted() {
        if (
            !this.$route.matched.some(
                (record) =>
                    record.meta.allowAnonymous || record.meta.allowOnlyAnonymous
            )
        ) {
            store.dispatch("account/" + AccountActions.VerifyLogin);
        }
    }
})
export default class App extends Vue {
    get layout(): string {
        return this.$route?.meta?.layout ?? "MainLayout";
    }
}
