import { render, staticRenderFns } from "./SideMenuTitle.html?vue&type=template&id=0dd88390&scoped=true&"
import script from "./SideMenuTitle.vue?vue&type=script&lang=ts&"
export * from "./SideMenuTitle.vue?vue&type=script&lang=ts&"
import style0 from "./SideMenuTitle.scss?vue&type=style&index=0&id=0dd88390&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dd88390",
  null
  
)

export default component.exports