
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class AppFooter extends Vue {
    readonly version: string = process.env.VUE_APP_VERSION;

    get currentYear(): number {
        return new Date().getFullYear();
    }
}
