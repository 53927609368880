
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Comment } from "@/models/case/Comment";
import { nonUserModule } from "@/store/modules/nonUser";
import { NonUserGetters } from "@/store/modules/nonUser/getters";
import { NonUserMutations } from "@/store/modules/nonUser/mutations";
import CommentEditorCore from "@/components/Case/CommentEditorCore";

@Component({
    components: { CommentEditorCore },
    computed: {
        ...nonUserModule.mapGetters({
            comment: NonUserGetters.CurrentComment
        })
    },
    methods: {
        ...nonUserModule.mapMutations({
            updateCurrentComment: NonUserMutations.UpdateCurrentComment
        })
    }
})
export default class NonUserCommentEditor extends Vue {
    private readonly comment?: Comment | null;
    private readonly updateCurrentComment!: (payload: Partial<Comment>) => void;

    save(): void {
        this.$emit("save");
    }

    cancel(): void {
        this.$emit("cancel");
    }
}
