
import { UserSubstitute } from "@/models/User";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { getUserFullName } from "@/services/userUtils";

@Component({ methods: { getUserFullName } })
export default class UserSubstituteChip extends Vue {
    @Prop({ type: Object, required: true })
    public userSubstitute!: UserSubstitute;

    get chipText(): string {
        const fromLocaleString = new Date(
            this.userSubstitute.dateFrom
        ).toLocaleDateString();
        const toLocaleString = new Date(
            this.userSubstitute.dateTo
        ).toLocaleDateString();
        const substituteFullName = getUserFullName(
            this.userSubstitute.substitute
        );

        return `${fromLocaleString} - ${toLocaleString}, ${substituteFullName}`;
    }

    click(): void {
        this.$emit("click");
    }
}
