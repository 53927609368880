
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import { User, UserSubstitute } from "@/models/User";
import { substituteModule } from "@/store/modules/substitute";
import { SubstituteGetters } from "@/store/modules/substitute/getters";
import { SubstituteActions } from "@/store/modules/substitute/actions";
import { getUserFullName } from "@/services/userUtils";
import UserSubstituteChip from "@/components/Shared/UserSubstituteChip";
import SubstituteEditor from "@/components/Shared/SubstituteEditor";
import UserAutocomplete from "@/components/Shared/UserAutocomplete";
import { SubstituteMutations } from "@/store/modules/substitute/mutations";

@Component({
    components: {
        UserSubstituteChip,
        SubstituteEditor,
        UserAutocomplete
    },
    computed: {
        ...substituteModule.mapGetters({
            userId: SubstituteGetters.UserId,
            users: SubstituteGetters.Users,
            userSubstitutes: SubstituteGetters.UserSubstitutes
        })
    },
    methods: {
        ...substituteModule.mapMutations({
            setUserId: SubstituteMutations.SetUserId,
            setUserSubstitutes: SubstituteMutations.SetUserSubstitutes,
            setEditingSubstitute: SubstituteMutations.SetEditingSubstitute
        }),
        ...substituteModule.mapActions({
            loadUsers: SubstituteActions.LoadUsers,
            loadUserSubstitutes: SubstituteActions.LoadUserSubstitutes,
            deleteUserSubstitute: SubstituteActions.DeleteUserSubstitute
        }),
        getUserFullName
    }
})
export default class AdminSubstitutePopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    protected userId!: number;
    protected users!: User[];
    protected userSubstitutes!: UserSubstitute[];

    protected setUserId!: (userId: number | null) => void;
    protected setUserSubstitutes!: (userSubstitutes: UserSubstitute[]) => void;
    protected setEditingSubstitute!: (
        userSubstitute: Partial<UserSubstitute>
    ) => void;

    protected readonly loadUsers!: () => Promise<void>;
    protected readonly loadUserSubstitutes!: () => Promise<void>;
    protected readonly deleteUserSubstitute!: (
        userSubstituteId: number
    ) => Promise<void>;

    protected substituteEditorVisible = false;

    get user(): number | null {
        return this.userId;
    }
    set user(value: number | null) {
        this.setUserId(value);

        if (value) this.loadUserSubstitutes();
        else this.setUserSubstitutes([]);
    }

    get activeUsers(): User[] {
        return this.users.filter((el) => el.isEnabled);
    }

    showSubstituteEditor(userSubstitute: UserSubstitute | undefined): void {
        this.setEditingSubstitute(userSubstitute ?? {});
        this.substituteEditorVisible = true;
    }

    async deleteSubstitute(userSubstitute: UserSubstitute): Promise<void> {
        await this.deleteUserSubstitute(userSubstitute.userSubstituteId);
    }

    @Watch("popupVisible")
    async popupVisibleHandle(value: boolean): Promise<void> {
        if (!value) return;

        await this.loadUsers();
        this.setUserId(null);
        this.setUserSubstitutes([]);
    }
}
