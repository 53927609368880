import Vue from "vue";
import router from "./router";
import store from "./store";

import "./register-hooks";

import App from "./App.vue";

import "./assets/devextreme-theme/dx.generic.custom-scheme.css";
import "flag-icons/css/flag-icons.css";
import "./assets/scss/index.scss";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = true;

new Vue({
    store: store,
    router: router,
    vuetify,
    render: (h) => h(App)
}).$mount("#app");
