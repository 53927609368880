
import hostedByLogo from "@/assets/img/gkk_dialog_positiv.jpg";
import Vue from "vue";
import Component from "vue-class-component";
import UserBadge from "@/components/Shared/UserBadge";

@Component({
    components: { UserBadge }
})
export default class AppHeader extends Vue {
    hostedByLogo: string = hostedByLogo;
}
