
import Vue from "vue";
import Component from "vue-class-component";
import UserList from "@/components/User/UserList";
import { userModule } from "@/store/modules/user";
import { roleModule } from "@/store/modules/role";
import { UserActions } from "@/store/modules/user/actions";
import { RoleActions } from "@/store/modules/role/actions";
import { Routes } from "@/router/routes";
import { RoleGetters } from "@/store/modules/role/getters";
import VerticalExpansionPanelWrapper from "@/components/Shared/VerticalExpansionPanelWrapper";
import { DataOptions } from "vuetify";
import { GetByPageRequest } from "@/services/requestUtils";
import { departmentModule } from "@/store/modules/department";
import { DepartmentActions } from "@/store/modules/department/actions";
import { Department } from "@/models/department/Department";
import { DepartmentGetters } from "@/store/modules/department/getters";

@Component({
    components: {
        UserList,
        VerticalExpansionPanelWrapper
    },
    computed: {
        ...roleModule.mapGetters({
            roles: RoleGetters.Roles
        }),
        ...departmentModule.mapGetters({
            enabledDepartments: DepartmentGetters.EnabledDepartments
        })
    },
    methods: {
        ...userModule.mapActions({
            loadUsersByPage: UserActions.LoadUsersByPage
        }),
        ...roleModule.mapActions({
            loadRoles: RoleActions.LoadRoles
        }),
        ...departmentModule.mapActions({
            loadDepartments: DepartmentActions.LoadDepartments
        })
    }
})
export default class Users extends Vue {
    private readonly loadUsersByPage!: (
        payload: GetByPageRequest
    ) => Promise<void>;
    private readonly loadRoles!: () => Promise<void>;
    private readonly loadDepartments!: () => Promise<void>;
    protected readonly roles!: string[];
    protected readonly enabledDepartments!: Department[];

    protected dataTableOptions: DataOptions | null = null;
    protected page = 1;

    protected firstNameFilterValue = "";
    protected lastNameFilterValue = "";
    protected emailFilterValue = "";
    protected roleFilterValue = [];
    protected departmentFilterValue = [];

    protected readonly headers = [
        { text: "Vorname Nachname", value: "fullName" },
        { text: "E-mail", value: "email" },
        { text: "Rolle", value: "role" },
        {
            text: "Aktiv",
            value: "active",
            width: "80px",
            align: "center"
        },
        {
            text: "Aktionen",
            value: "actions",
            sortable: false,
            width: "80px",
            align: "center"
        }
    ];

    resetFilters(): void {
        this.firstNameFilterValue = "";
        this.lastNameFilterValue = "";
        this.emailFilterValue = "";
        this.roleFilterValue = [];
        this.departmentFilterValue = [];
    }

    get getFilterQueries(): Array<{ name: string; value: unknown }> {
        const filterQueries = [];
        filterQueries.push({
            name: "firstName",
            value: this.firstNameFilterValue
        });
        filterQueries.push({
            name: "lastName",
            value: this.lastNameFilterValue
        });
        filterQueries.push({
            name: "email",
            value: this.emailFilterValue
        });
        filterQueries.push({
            name: "roles",
            value: this.roleFilterValue
        });
        filterQueries.push({
            name: "departments",
            value: this.departmentFilterValue
        });
        return filterQueries;
    }

    created(): void {
        this.loadRoles();
        this.loadDepartments();
    }

    addNewUser(): void {
        this.$router.push(Routes.CreateUser);
    }

    async updateData(options: DataOptions): Promise<void> {
        this.dataTableOptions = options;
        await this.loadUsersByPage({
            options: this.dataTableOptions,
            filterQueries: this.getFilterQueries
        });
    }

    async applyFilters(): Promise<void> {
        await this.loadUsersByPage({
            options: this.dataTableOptions,
            filterQueries: this.getFilterQueries
        });
        this.page = 1;
    }

    async reload(): Promise<void> {
        await this.loadUsersByPage({
            options: this.dataTableOptions,
            filterQueries: this.getFilterQueries
        });
    }
}
