import { MutationTree } from "vuex";
import { NonUserState } from ".";
import { AccessEmail } from "@/models/nonUser/AccessEmail";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";

export enum NonUserMutations {
    SetAccessEmail = "setCases",
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    SetCase = "setCase",
    AddComment = "addComment",
    SetCurrentComment = "setCurrentComment",
    UpdateCurrentComment = "updateCurrentComment"
}

const mutations: MutationTree<NonUserState> = {
    [NonUserMutations.SetAccessEmail](state, payload: AccessEmail): void {
        state.accessEmail = payload;
    },
    [NonUserMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [NonUserMutations.SetLoading](state, payload: boolean): void {
        state.loading = payload;
    },
    [NonUserMutations.SetCase](state, payload: Case) {
        state.case = payload;
    },
    [NonUserMutations.SetCurrentComment](state, payload: Comment) {
        state.currentComment = JSON.parse(JSON.stringify(payload));
    },
    [NonUserMutations.AddComment](state, payload: Comment): void {
        if (state.case?.comments) {
            state.case.comments.unshift(payload);
        }
    },
    [NonUserMutations.UpdateCurrentComment](
        state,
        payload: Partial<Comment>
    ): void {
        if (state.currentComment) {
            state.currentComment = { ...state.currentComment, ...payload };
        }
    }
};

export default mutations;
