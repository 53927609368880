import { User, UserSubstitute } from "@/models/User";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { SubstituteState } from ".";

export enum SubstituteGetters {
    UserId = "userId",
    Users = "users",
    UserSubstitutes = "userSubstitutes",
    EditingSubstitute = "editingSubstitutes",
    Errors = "errors",
    Loading = "loading"
}

const getters: GetterTree<SubstituteState, RootState> = {
    [SubstituteGetters.UserId](state): number | null {
        return state.userId;
    },
    [SubstituteGetters.Users](state): User[] {
        return state.users;
    },
    [SubstituteGetters.UserSubstitutes](state): UserSubstitute[] {
        return state.userSubstitutes;
    },
    [SubstituteGetters.EditingSubstitute](state): Partial<UserSubstitute> {
        return state.editingSubstitute;
    },
    [SubstituteGetters.Loading](state): boolean {
        return state.loading;
    },
    [SubstituteGetters.Errors](state): string[] | null {
        return state.errors;
    }
};

export default getters;
