
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import { userModule } from "@/store/modules/user";
import { UserActions } from "@/store/modules/user/actions";
import DepartmentEditor from "@/components/Department/DepartmentEditor";
import { UserGetters } from "@/store/modules/user/getters";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import { User } from "@/models/User";
import { DepartmentGetters } from "@/store/modules/department/getters";
import { DepartmentActions } from "@/store/modules/department/actions";
import { departmentModule } from "@/store/modules/department";
import { DepartmentMutations } from "@/store/modules/department/mutations";

@Component({
    components: { DepartmentEditor },
    computed: {
        ...departmentModule.mapGetters({
            isEdited: DepartmentGetters.IsEdited,
            isLoading: DepartmentGetters.Loading,
            errors: DepartmentGetters.Errors
        }),
        ...userModule.mapGetters({
            users: UserGetters.Users
        })
    },
    methods: {
        ...departmentModule.mapActions({
            loadDepartment: DepartmentActions.LoadDepartment,
            updateDepartment: DepartmentActions.UpdateDepartment
        }),
        ...departmentModule.mapMutations({
            setDepartment: DepartmentMutations.SetDepartment,
            setErrors: DepartmentMutations.SetErrors
        }),
        ...userModule.mapActions({
            loadUsers: UserActions.LoadUsers
        })
    }
})
export default class EditDepartment extends Vue {
    private readonly loadDepartment!: (departmentId: number) => Promise<void>;
    private readonly updateDepartment!: () => Promise<void>;
    private readonly setDepartment!: (user: Partial<User> | null) => void;
    private readonly loadUsers!: () => Promise<void>;
    private readonly setErrors!: (payload: string[] | null) => void;

    protected readonly isLoading!: boolean;
    protected readonly isEdited!: boolean;
    private readonly errors!: string[] | null;

    private leaveConfirmationEnabled = true;
    protected notificationMessage = "";
    protected notificationVisible = false;

    created(): void {
        this.loadUsers();

        if (this.departmentId) {
            this.setDepartment(null);
            this.loadDepartment(this.departmentId);
        }
        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    saveDepartment(): void {
        this.updateDepartment().then(() => {
            const errors = this.errors;
            this.setErrors(null);
            if (errors !== undefined && errors !== null) {
                this.showNotification(errors.join(" "));
            } else {
                this.leaveConfirmationEnabled = false;
                this.$router.push(Routes.Departments);
            }
        });
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }

    get departmentId(): number | null {
        const departmentId = Number(this.$route.params.id);
        return !isNaN(departmentId) ? departmentId : null;
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
