
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import UserSubstituteChip from "@/components/Shared/UserSubstituteChip";
import SubstituteEditor from "@/components/Shared/SubstituteEditor";
import { substituteModule } from "@/store/modules/substitute";
import { SubstituteGetters } from "@/store/modules/substitute/getters";
import { SubstituteActions } from "@/store/modules/substitute/actions";
import { UserSubstitute } from "@/models/User";
import { getCurrentUser } from "@/services/userUtils";
import { SubstituteMutations } from "@/store/modules/substitute/mutations";

@Component({
    components: {
        UserSubstituteChip,
        SubstituteEditor
    },
    computed: {
        ...substituteModule.mapGetters({
            userSubstitutes: SubstituteGetters.UserSubstitutes
        })
    },
    methods: {
        ...substituteModule.mapMutations({
            setUserId: SubstituteMutations.SetUserId,
            setUserSubstitutes: SubstituteMutations.SetUserSubstitutes,
            setEditingSubstitute: SubstituteMutations.SetEditingSubstitute
        }),
        ...substituteModule.mapActions({
            loadUsers: SubstituteActions.LoadUsers,
            deleteUserSubstitute: SubstituteActions.DeleteUserSubstitute
        }),
        getCurrentUser
    }
})
export default class UserSubstitutePopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    protected userSubstitutes!: UserSubstitute | null;

    protected setUserId!: (userId: number | null) => void;
    protected setUserSubstitutes!: (userSubstitutes: UserSubstitute[]) => void;
    protected setEditingSubstitute!: (
        userSubstitute: Partial<UserSubstitute>
    ) => void;

    protected loadUsers!: () => Promise<void>;

    protected readonly deleteUserSubstitute!: (
        userSubstituteId: number
    ) => Promise<void>;

    protected substituteEditorVisible = false;

    @Watch("popupVisible")
    async popupVisibleHandle(value: boolean): Promise<void> {
        if (!value) return;

        const currentUser = getCurrentUser();
        if (!currentUser) return;

        this.setUserId(currentUser.id);
        this.setUserSubstitutes(currentUser.userSubstitutes);
    }

    async showSubstituteEditor(
        userSubstitute: UserSubstitute | undefined
    ): Promise<void> {
        await this.loadUsers();
        this.setEditingSubstitute(userSubstitute ?? {});
        this.substituteEditorVisible = true;
    }

    async deleteSubstitute(userSubstitute: UserSubstitute): Promise<void> {
        await this.deleteUserSubstitute(userSubstitute.userSubstituteId);
    }
}
