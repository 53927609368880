import { ModuleTree } from "vuex";
import { RootState } from "..";
import user from "./user";
import account from "./account";
import role from "./role";
import caseItem from "./case";
import department from "./department";
import nonUser from "./nonUser";
import substitute from "./substitute";

const modules: ModuleTree<RootState> = {
    user,
    account,
    role,
    caseItem,
    department,
    nonUser,
    substitute
};

export default modules;
