
import Vue from "vue";
import Component from "vue-class-component";
import LoginFormData from "@/models/LoginFormData";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TwoFactorForm extends Vue {
    @Prop({ type: Object, required: true })
    public readonly loginData!: LoginFormData;

    protected valid = false;
    protected showPassword = false;

    protected codeRules = [
        (v: string): boolean | string => {
            return !!v || "Code wird benötigt.";
        },
        (v: string): boolean | string => {
            return (
                /^(\d{6}|[a-z0-9]{8})$/.test(v) ||
                "Code muss 6 oder 8 Zeichen lang sein."
            );
        }
    ];

    get twoFactorPassword(): string {
        return this.loginData.twoFactorPassword;
    }

    set twoFactorPassword(twoFactorPassword: string) {
        this.loginData.twoFactorPassword = twoFactorPassword;
    }

    submit(): void {
        (this.$refs.codeForm as Vue & { validate: () => boolean }).validate();
        if (this.valid) {
            this.$emit("submit", this.loginData);
        }
    }
}
