
import Vue from "vue";
import { CaseState, caseModule } from "@/store/modules/case";
import { CaseGetters } from "@/store/modules/case/getters";
import { CaseActions } from "@/store/modules/case/actions";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { CaseListItem } from "@/models/case/CaseListItem";
import { Routes } from "@/router/routes";
import {
    getUserFullName,
    getUserInitials,
    isUserInRoles
} from "@/services/userUtils";
import { footerPageText } from "@/services/dataTableUtils";
import { CaseUrgency } from "@/models/case/Case";
import { DataOptions, DataTableHeader } from "vuetify/types";
import { Role } from "@/models/User";
import ConfirmPopup from "@/components/Shared/ConfirmPopup";

@Component({
    components: { ConfirmPopup },
    methods: {
        ...caseModule.mapActions({
            deleteCaseById: CaseActions.DeleteCase
        }),
        getUserFullName,
        getUserInitials,
        footerPageText
    },
    computed: {
        ...caseModule.mapGetters({
            cases: CaseGetters.Cases,
            totalCases: CaseGetters.TotalCases,
            loading: CaseGetters.Loading
        })
    }
})
export default class CaseList extends Vue {
    @Prop({ type: Array })
    public headers!: Array<DataTableHeader>;

    @Prop({ type: Boolean })
    public isPanelExpanded!: boolean;

    @PropSync("options", { type: Object })
    public optionsSync!: DataOptions;

    protected cases!: CaseState["cases"];

    private readonly deleteCaseById!: (caseId: number) => Promise<void>;

    protected titleColumnStyle = "max-width: 140px";
    protected confirmVisible = false;
    protected caseToDelete: CaseListItem | null = null;

    @Watch("isPanelExpanded")
    updateStyles(): void {
        this.$nextTick(() => {
            this.titleColumnStyle = `max-width: ${
                document
                    .getElementsByClassName("titleColumn")[0]
                    ?.getClientRects()[0].width
            }px`;
        });
    }

    @Watch("cases")
    casesWatch(cases: CaseState["cases"]): void {
        if (!cases.length && this.optionsSync.page > 1)
            this.optionsSync.page = 1;
    }

    mounted(): void {
        this.titleColumnStyle = `max-width: ${
            document
                .getElementsByClassName("titleColumn")[0]
                ?.getClientRects()[0].width
        }px`;
    }

    get isAdminOrSU(): boolean {
        return isUserInRoles([Role.SU, Role.Admin]);
    }

    urgencyColor(urgency: CaseUrgency): string {
        switch (urgency) {
            case CaseUrgency.Low:
                return "indigo";
            case CaseUrgency.Medium:
                return "orange lighten-1";
            case CaseUrgency.High:
                return "red darken-4";
        }
    }

    viewCase(caseItem: CaseListItem): void {
        if (caseItem?.id) {
            this.$router.push(Routes.Case + caseItem.id);
        }
    }

    showConfirmDialog(item: CaseListItem): void {
        this.caseToDelete = item;
        this.confirmVisible = true;
    }

    confirm(): void {
        if (this.caseToDelete) this.deleteCase(this.caseToDelete);
    }

    async deleteCase(caseItem: CaseListItem): Promise<void> {
        if (caseItem?.id) {
            await this.deleteCaseById(caseItem.id);
            this.$emit("reload");
        }
    }
}
