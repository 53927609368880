/* eslint-disable no-unused-vars */
export enum Routes {
    Login = "/account/login",
    RequestPasswordReset = "/account/request-password-reset",
    PasswordReset = "/account/password-reset",
    AccountActivation = "/account/activate",
    TwoFactorActivation = "/account/2fa",
    Error = "/error/",
    Users = "/users",
    EditUser = "/users/edit/",
    CreateUser = "/users/create",
    Cases = "/",
    Case = "/cases/",
    NonUserCase = "/non-user/cases/",
    CreateCase = "/cases/create",
    Departments = "/departments",
    Department = "/departments/",
    CreateDepartment = "/departments/create",
    EditDepartment = "/departments/edit/"
}
