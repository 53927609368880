import { Department } from "@/models/department/Department";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { DepartmentState } from ".";

export enum DepartmentGetters {
    Departments = "departments",
    EnabledDepartments = "enabledDepartments",
    TotalDepartments = "totalDepartments",
    Department = "department",
    Errors = "errors",
    Loading = "loading",
    IsEdited = "isEdited"
}

const getters: GetterTree<DepartmentState, RootState> = {
    [DepartmentGetters.Departments](state): Department[] {
        return state.departments;
    },
    [DepartmentGetters.EnabledDepartments](state): Department[] {
        return state.departments.filter((d) => d.isEnabled);
    },
    [DepartmentGetters.TotalDepartments](state): number | null {
        return state.totalDepartments;
    },
    [DepartmentGetters.Department](state): Partial<Department> | null {
        return state.department;
    },
    [DepartmentGetters.Loading](state): boolean {
        return state.loading;
    },
    [DepartmentGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [DepartmentGetters.IsEdited](state): boolean {
        return state.isEdited;
    }
};

export default getters;
