import { User } from "@/models/User";
import { MutationTree } from "vuex";
import { UserState } from ".";

export enum UserMutations {
    SetUsers = "addUsers",
    SetTotalUsers = "setTotalUsers",
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    RemoveUserById = "removeUserById",
    SetUser = "setUser",
    UpdateUser = "updateUser"
}

const mutations: MutationTree<UserState> = {
    [UserMutations.SetUsers](state, payload: User[]): void {
        state.users = payload;
    },
    [UserMutations.SetTotalUsers](state, payload: number): void {
        state.totalUsers = payload;
    },
    [UserMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [UserMutations.SetLoading](state, payload: boolean): void {
        state.loading = payload;
    },
    [UserMutations.RemoveUserById](state, payload: number) {
        state.users = state.users.filter((u) => u.id !== payload);
    },
    [UserMutations.SetUser](state, payload: Partial<User> | null) {
        state.user = payload;
        state.isEdited = false;
    },
    [UserMutations.UpdateUser](state, payload: Partial<User>) {
        if (state.user) {
            state.user = { ...state.user, ...payload };
            state.isEdited = true;
        }
    }
};

export default mutations;
