import { Session } from "@/models/Session";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { AccountState } from ".";
import { LoginResult } from "@/models/LoginResult";
import { UserSubstitute } from "@/models/User";

export enum AccountGetters {
    Session = "session",
    LoginResult = "loginResult",
    Errors = "errors",
    Substitutes = "substitutes"
}

const getters: GetterTree<AccountState, RootState> = {
    [AccountGetters.Session](state): Session | null {
        return state.session;
    },
    [AccountGetters.LoginResult](state): LoginResult | null {
        return state.loginResult;
    },
    [AccountGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [AccountGetters.Substitutes](state): UserSubstitute[] {
        return state.session?.User.userSubstitutes ?? [];
    }
};

export default getters;
