import { CaseStatus, CaseType, CaseUrgency } from "@/models/case/Case";

const emailRules = [
    (v: string): boolean | string => {
        return !!v || "Email ist ein Pflichtfeld";
    },
    (v: string): boolean | string => {
        return /.+@.+\..+/.test(v) || "Email ist falsch";
    }
];

const passwordRules = [
    (v: string): boolean | string => {
        return !!v || "Passwort ist ein Pflichtfeld";
    },
    (v: string): boolean | string => {
        return v.length >= 6 || "Passwort muss mindestens 6 Zeichen lang sein";
    },
    (v: string): boolean | string => {
        return v.length <= 40 || "Passwort darf höchstens 40 Zeichen lang sein";
    },
    (v: string): boolean | string => {
        return passwordRequiredPattern.test(v) || requiredCharacters;
    },
    (v: string): boolean | string => {
        return passwordAllowedPattern.test(v) || allowedSpecialCharacters;
    }
];

const passwordRequiredPattern = new RegExp(
    "^(?=.*[\"!$%&\\/()=?~{\\[\\]}\\@+\\-_#'<>|,;.:])(?=.*[a-zA-Z])(?=.*\\d).*$"
);

const passwordAllowedPattern = new RegExp(
    "^[A-Za-z\\d!\"$%&\\/()=?~{\\[\\]}\\\\@+-_#'<>|,;.:]*$"
);

const requiredCharacters =
    "Passwort muss mindestens eine Ziffer, \n einen Buchstaben und eines dieser Sonderzeichen enthalten: \n" +
    " ! \" $ % & / ( ) = ? ~ { [ ] } \\ @ + - _ # ' < > | , ; . :";

const allowedSpecialCharacters =
    "Passwort darf nur römische Buchstaben, \n Zahlen und diese Sonderzeichen enthalten: \n" +
    " ! \" $ % & / ( ) = ? ~ { [ ] } \\ @ + - _ # ' < > | , ; . :";

const titleRules = [
    (v: string): boolean | string => {
        return !!v || "Titel ist ein Pflichtfeld";
    },
    (v: string): boolean | string => {
        return v.length <= 100 || "Titel darf höchstens 100 Zeichen lang sein";
    }
];

const descriptionRules = [
    (v: string): boolean | string => {
        return !!v || "Beschreibung ist ein Pflichtfeld";
    },
    (v: string): boolean | string => {
        return (
            v.length <= 8000 ||
            "Beschreibung darf höchstens 8000 Zeichen lang sein"
        );
    }
];

const typeRules = [
    (v: CaseType): boolean | string => {
        return !!v || "Typ ist ein Pflichtfeld";
    }
];

const departmentRules = [
    (v: number): boolean | string => {
        return !!v || "Team ist ein Pflichtfeld";
    }
];

const salesforceIdRules = [
    (v: string): boolean | string => {
        return (
            v.length <= 7 ||
            "SalesforceId Beschreibung darf höchstens 7 Zeichen lang sein"
        );
    }
];

const customerNameRules = [
    (v: string): boolean | string => {
        return !!v || "Kundenname ist ein Pflichtfeld";
    },
    (v: string): boolean | string => {
        return (
            v.length <= 60 || "Kundenname darf höchstens 60 Zeichen lang sein"
        );
    }
];

const receivingDateRules = [
    (v: Date): boolean | string => {
        return !!v || "Eingangsdatum ist ein Pflichtfeld";
    }
];

const statusRules = [
    (v: CaseStatus): boolean | string => {
        return !!v || "Status ist ein Pflichtfeld";
    }
];

const urgencyRules = [
    (v: CaseUrgency): boolean | string => {
        return !!v || "Dringlichkeit ist ein Pflichtfeld";
    }
];

const responsibleRules = [
    (v: number): boolean | string => {
        return !!v || "Verantwortlich ist ein Pflichtfeld";
    }
];

export {
    emailRules,
    passwordRules,
    titleRules,
    typeRules,
    descriptionRules,
    departmentRules,
    salesforceIdRules,
    customerNameRules,
    receivingDateRules,
    statusRules,
    urgencyRules,
    responsibleRules
};
