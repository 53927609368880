import { CaseListItem } from "@/models/case/CaseListItem";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";
import api from ".";
import { AxiosResponse } from "axios";
import {
    CaseCreateResponse,
    CaseUpdateResponse,
    GetByPageRequest
} from "@/services/requestUtils";

export const caseDataSourceEndpoint = `${api.defaults.baseURL}cases/datasource`;

async function getCases(payload: GetByPageRequest): Promise<{
    cases: CaseListItem[];
    totalCount: number;
}> {
    const response = await api.post("/cases/page", payload);
    return response.data;
}

function exportCases(payload: GetByPageRequest): Promise<{
    filename: string;
    data: Blob;
}> {
    return api
        .post("cases/export", payload, { responseType: "blob" })
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename=(.+)/)[1]
            };
        });
}

async function getCase(caseId: number): Promise<Case> {
    const response = await api.get("/cases/" + caseId);
    return response.data;
}

async function createCase(caseItem: Case): Promise<CaseCreateResponse> {
    const response = await api.post("/cases", caseItem);
    return response.data;
}

async function updateCase(caseItem: Case): Promise<CaseUpdateResponse> {
    const response = await api.put("/cases/" + caseItem.id, caseItem);
    return response.data;
}

async function deleteCase(caseId: number): Promise<void> {
    await api.delete("/cases/" + caseId);
}

function uploadAttachment(
    caseId: number,
    attachment: File
): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("attachment", attachment);
    return api.post(`cases/${caseId}/attachments`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

function deleteAttachment(
    caseId: number,
    attachmentId: number
): Promise<AxiosResponse> {
    return api.delete(`cases/${caseId}/attachments/${attachmentId}`);
}

async function createComment(comment: Comment): Promise<Event> {
    const response = await api.post("/comments", comment);
    return response.data;
}

async function updateComment(comment: Comment): Promise<Event> {
    const response = await api.put("/comments/" + comment.id, comment);
    return response.data;
}

async function deleteComment(commentId: number): Promise<void> {
    await api.delete("/comments/" + commentId);
}

function downloadAttachment(
    attachementId: number,
    caseId: number
): Promise<{
    filename: string;
    data: Blob;
}> {
    return api
        .get(`cases/${caseId}/attachments/${attachementId}`, {
            responseType: "blob"
        })
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename=(.+)/)[1]
            };
        });
}

export default {
    getCases,
    exportCases,
    getCase,
    createCase,
    updateCase,
    deleteCase,
    uploadAttachment,
    deleteAttachment,
    createComment,
    updateComment,
    deleteComment,
    downloadAttachment
};
