import caseApi from "@/api/caseApi";
import {
    CaseCreateResponse,
    GetByPageRequest,
    makeRequest,
    saveAttachment
} from "@/services/requestUtils";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";
import { RootState } from "@/store";
import { ActionContext, ActionTree, Commit } from "vuex";
import { CaseState } from ".";
import { CaseMutations } from "./mutations";
import { getErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import { UserActions } from "@/store/modules/user/actions";
import { saveData } from "@/services/saveData";

export enum CaseActions {
    LoadCases = "loadCases",
    Export = "export",
    LoadCase = "loadCase",
    AddNewCase = "addNewCase",
    CreateCase = "createCase",
    SaveAttachments = "saveAttachments",
    DeleteCase = "deleteCase",
    SaveCase = "saveCase",
    AddNewCurrentComment = "addNewComment",
    CreateComment = "createComment",
    UpdateComment = "updateComment",
    DeleteComment = "deleteComment",
    DownloadAttachment = "downloadAttachment"
}

async function saveCaseAttachments(
    caseId: number,
    attachments: File[],
    removeAttachmentsId: number[]
): Promise<void> {
    await saveAttachment(
        caseId,
        attachments,
        removeAttachmentsId,
        caseApi.uploadAttachment,
        caseApi.deleteAttachment
    );
}

async function makeCaseRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        CaseMutations.SetErrors,
        CaseMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<CaseState, RootState> = {
    async [CaseActions.LoadCases](
        context,
        payload: GetByPageRequest
    ): Promise<void> {
        await makeCaseRequest(
            context.commit,
            () => caseApi.getCases(payload),
            (result) => {
                context.commit(CaseMutations.SetCases, result.cases);
                context.commit(CaseMutations.SetTotalCases, result.totalCount);
            }
        );
    },
    async [CaseActions.LoadCase](context, payload: number): Promise<void> {
        await makeCaseRequest(
            context.commit,
            () => caseApi.getCase(payload),
            (result) => {
                context.commit(CaseMutations.SetCase, result);
                context.commit(CaseMutations.ClearAttachments);
            }
        );
    },
    [CaseActions.AddNewCase](context): void {
        context.dispatch("user/" + UserActions.LoadUsers, null, {
            root: true
        });

        const caseItem: Partial<Case> = {
            title: "",
            description: ""
        };
        context.commit(CaseMutations.SetCase, caseItem);
    },
    async [CaseActions.CreateCase]({
        commit,
        state
    }): Promise<CaseCreateResponse | undefined> {
        const caseItem = state.case;
        if (!caseItem) return undefined;

        let result;
        await makeCaseRequest(
            commit,
            async () => (result = await caseApi.createCase(caseItem))
        );
        return result;
    },
    async [CaseActions.SaveAttachments](
        { commit, state },
        payload: number
    ): Promise<void> {
        await makeCaseRequest(commit, async () => {
            await saveCaseAttachments(
                payload,
                state.newAttachments,
                state.removedAttachmentIds
            );
            commit(CaseMutations.ClearAttachments);
        });
    },
    async [CaseActions.DeleteCase](context, payload: number): Promise<void> {
        await makeCaseRequest(
            context.commit,
            () => caseApi.deleteCase(payload),
            () => context.commit(CaseMutations.RemoveCase, payload)
        );
    },
    async [CaseActions.SaveCase](context): Promise<boolean> {
        let isResponsibleSubstituted = false;
        if (context.state.case) {
            const caseItem = context.state.case;
            await makeCaseRequest(
                context.commit,
                () => caseApi.updateCase(caseItem),
                (result) => {
                    if (result.historyItems) {
                        result.historyItems.forEach((historyItem) => {
                            historyItem.creationDate = new Date(
                                historyItem.creationDate
                            );
                            context.commit(
                                CaseMutations.AddHistoryItem,
                                historyItem
                            );
                        });
                    }
                    isResponsibleSubstituted = result.isResponsibleSubstituted;
                }
            );
            try {
                if (caseItem.id) {
                    await saveCaseAttachments(
                        caseItem.id,
                        context.state.newAttachments,
                        context.state.removedAttachmentIds
                    );
                    context.commit(CaseMutations.ClearAttachments);
                }
            } catch (e) {
                handleReponseError(e as Error, context);
            }
        }

        return isResponsibleSubstituted;
    },
    [CaseActions.AddNewCurrentComment](context): void {
        const comment: Partial<Comment> = {
            text: "",
            caseId: context.state.case?.id
        };
        context.commit(CaseMutations.SetCurrentComment, comment);
    },
    async [CaseActions.CreateComment](context): Promise<void> {
        const comment = context.state.currentComment;
        if (comment) {
            await makeCaseRequest(
                context.commit,
                () => caseApi.createComment(comment),
                (result) => context.commit(CaseMutations.AddComment, result)
            );
        }
    },
    async [CaseActions.UpdateComment](context): Promise<void> {
        const comment = context.state.currentComment;
        if (comment) {
            await makeCaseRequest(
                context.commit,
                () => caseApi.updateComment(comment),
                (result) => context.commit(CaseMutations.UpdateComment, result)
            );
        }
    },
    async [CaseActions.DeleteComment](context, payload: number): Promise<void> {
        await makeCaseRequest(
            context.commit,
            () => caseApi.deleteComment(payload),
            () => context.commit(CaseMutations.RemoveComment, payload)
        );
    },
    async [CaseActions.Export](
        context,
        payload: GetByPageRequest
    ): Promise<void> {
        await makeCaseRequest(
            context.commit,
            () => caseApi.exportCases(payload),
            (file) => {
                saveData(file.data, file.filename);
            }
        );
    },
    async [CaseActions.DownloadAttachment](
        context,
        payload: {
            attachmentId: number;
            caseId: number;
        }
    ): Promise<void> {
        await makeCaseRequest(
            context.commit,
            () =>
                caseApi.downloadAttachment(
                    payload.attachmentId,
                    payload.caseId
                ),
            (file) => {
                saveData(file.data, file.filename);
            }
        );
    }
};

export function handleReponseError(
    error: Error,
    context: ActionContext<CaseState, RootState>
): void {
    context.commit(
        CaseMutations.SetErrors,
        getErrorTextsFromErrorResponse(error)
    );
}

export default actions;
