
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import InfoPopup from "@/components/Shared/InfoPopup";

@Component({
    components: {
        InfoPopup
    }
})
export default class FileUploadErrorPopup extends Vue {
    @PropSync("showDialog", { type: Boolean, required: true })
    protected showDialogSync!: boolean;

    @Prop({ type: Array, required: true })
    protected files!: string[];
}
