import { CaseListItem } from "@/models/case/CaseListItem";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { CaseState } from ".";

export enum CaseGetters {
    Cases = "cases",
    Case = "case",
    TotalCases = "totalCases",
    Errors = "errors",
    Loading = "loading",
    CurrentComment = "currentComment",
    NewAttachmenst = "newAttachmenst"
}

const getters: GetterTree<CaseState, RootState> = {
    [CaseGetters.Cases](state): CaseListItem[] {
        return state.cases;
    },
    [CaseGetters.Case](state): Partial<Case> | null {
        return state.case;
    },
    [CaseGetters.TotalCases](state): number | null {
        return state.totalCases;
    },
    [CaseGetters.Loading](state): boolean {
        return state.loading;
    },
    [CaseGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [CaseGetters.CurrentComment](state): Comment | null {
        return state.currentComment;
    },
    [CaseGetters.NewAttachmenst](state): File[] {
        return state.newAttachments;
    }
};

export default getters;
