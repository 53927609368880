import { AxiosError } from "axios";
import { ValidationReponseData } from "@/models/ValidationResponseData";

export function getErrorTextsFromErrorResponse(error: Error): Array<string> {
    const axiosError = error as AxiosError;
    if (axiosError.response === undefined) return [error.message];
    if (axiosError.response.data === undefined)
        return [axiosError.response.statusText];

    const validationData = axiosError.response.data as ValidationReponseData;

    if (validationData.errors !== undefined) {
        return Object.entries(validationData.errors).map((error) => {
            return `validation-problem -> property: ${error[0]} message: ${error[1]}`;
        });
    }

    return [axiosError.response.statusText, axiosError.response.data];
}
