import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";
import { HistoryItem } from "@/models/case/HistoryItem";
import { AccessEmail } from "@/models/nonUser/AccessEmail";
import api from ".";

async function sendAccess(payload: AccessEmail): Promise<HistoryItem> {
    const response = await api.post("/non-user/access/send", payload);
    return response.data;
}

async function getCase(token: string, caseId: number): Promise<Case> {
    const response = await api.get(
        `/non-user/cases/?token=${token}&caseId=${caseId}`
    );
    return response.data;
}

async function createComment(token: string, comment: Comment): Promise<Event> {
    const response = await api.post(
        `/non-user/comments?token=${token}`,
        comment
    );
    return response.data;
}

function downloadAttachment(
    token: string,
    attachementId: number,
    caseId: number
): Promise<{
    filename: string;
    data: Blob;
}> {
    return api
        .get(
            `/non-user/cases/${caseId}/attachments/${attachementId}?token=${token}`,
            {
                responseType: "blob"
            }
        )
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename=(.+)/)[1]
            };
        });
}

export default {
    sendAccess,
    getCase,
    createComment,
    downloadAttachment
};
