import { CaseListItem } from "@/models/case/CaseListItem";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface CaseState {
    cases: CaseListItem[];
    case: Case | null;
    totalCases: number | null;
    errors: string[] | null;
    loading: boolean;
    newAttachments: File[];
    removedAttachmentIds: number[];
    currentComment: Comment | null;
}

const caseState: CaseState = {
    cases: [],
    case: null,
    totalCases: null,
    errors: null,
    loading: false,
    newAttachments: [],
    removedAttachmentIds: [],
    currentComment: null
};

const caseItem: Module<CaseState, RootState> = {
    namespaced: true,
    state: caseState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const caseModule = createNamespacedHelpers("caseItem");

export default caseItem;
