
import Vue from "vue";
import Component from "vue-class-component";
import SideMenuItem from "../SideMenuItem";
import SideMenuTitle from "../SideMenuTitle";
import { Routes } from "@/router/routes";
import { isUserInRoles } from "@/services/userUtils";
import { Role } from "@/models/User";

interface MenuItem {
    title: string;
    icon?: string;
    to?: string;
    allowedRoles?: string[];
}

interface MenuCategory extends MenuItem {
    items?: MenuItem[];
}

@Component({
    components: { SideMenuTitle, SideMenuItem }
})
export default class SideMenu extends Vue {
    readonly menuCategories: MenuCategory[] = [
        {
            title: "Cases",
            allowedRoles: [Role.SU, Role.Admin, Role.User],
            items: [
                {
                    title: "Übersicht",
                    icon: "format-list-bulleted-square",
                    to: Routes.Cases,
                    allowedRoles: [Role.SU, Role.Admin, Role.User]
                },
                {
                    title: "Neu",
                    icon: "plus-box",
                    to: Routes.CreateCase,
                    allowedRoles: [Role.SU, Role.Admin, Role.User]
                }
            ]
        },
        {
            title: "Konfiguration",
            allowedRoles: [Role.SU, Role.Admin],
            items: [
                {
                    title: "Benutzer",
                    icon: "account-group",
                    to: Routes.Users,
                    allowedRoles: [Role.SU, Role.Admin]
                },
                {
                    title: "Teams",
                    icon: "home-group",
                    to: Routes.Departments,
                    allowedRoles: [Role.SU, Role.Admin]
                }
            ]
        }
    ];

    isMenuItemVisible(roles?: string[]): boolean {
        if (roles) {
            return isUserInRoles(roles);
        }
        return true;
    }
}
