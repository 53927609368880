import { CaseListItem } from "@/models/case/CaseListItem";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";
import { MutationTree } from "vuex";
import { CaseState } from ".";
import { HistoryItem } from "@/models/case/HistoryItem";

export enum CaseMutations {
    SetCases = "setCases",
    SetCase = "setCase",
    SetTotalCases = "setTotalCases",
    UpdateCase = "updateCase",
    RemoveCase = "removeUserById",
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    AddAttachments = "addAttachments",
    RemoveAttachment = "removeAttachment",
    ClearAttachments = "clearAttachments",
    SetCurrentComment = "SetCurrentComment",
    UpdateCurrentComment = "updateCurrentComment",
    UpdateComment = "updateComment",
    RemoveComment = "removeComment",
    AddComment = "addComment",
    AddHistoryItem = "addHistoryItem"
}

const mutations: MutationTree<CaseState> = {
    [CaseMutations.SetCases](state, payload: CaseListItem[]): void {
        state.cases = payload;
    },
    [CaseMutations.SetCase](state, payload: Case) {
        state.case = payload;
    },
    [CaseMutations.SetTotalCases](state, payload: number): void {
        state.totalCases = payload;
    },
    [CaseMutations.UpdateCase](state, payload: Partial<Case>): void {
        if (state.case) {
            state.case = { ...state.case, ...payload };
        }
    },
    [CaseMutations.RemoveCase](state, payload: number) {
        state.cases = state.cases.filter((u) => u.id !== payload);
    },
    [CaseMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [CaseMutations.SetLoading](state, payload: boolean): void {
        state.loading = payload;
    },
    [CaseMutations.AddAttachments](state, payload: File[]): void {
        state.newAttachments = payload;
    },
    [CaseMutations.RemoveAttachment](state, payload: string | number): void {
        if (state.case) {
            const attachmentIndex = state.case.attachments.findIndex(
                (a) => a.id === payload
            );
            if (attachmentIndex > -1) {
                state.case.attachments.splice(attachmentIndex, 1);
                state.removedAttachmentIds.push(payload as number);
                return;
            }
        }
        const newAttachmentIndex = state.newAttachments.findIndex(
            (a) => a.name === payload
        );
        if (newAttachmentIndex > -1) {
            state.newAttachments.splice(newAttachmentIndex, 1);
        }
    },
    [CaseMutations.ClearAttachments](state): void {
        state.newAttachments = [];
        state.removedAttachmentIds = [];
    },
    [CaseMutations.SetCurrentComment](state, payload: Comment) {
        state.currentComment = JSON.parse(JSON.stringify(payload));
    },
    [CaseMutations.UpdateCurrentComment](
        state,
        payload: Partial<Comment>
    ): void {
        if (state.currentComment) {
            state.currentComment = { ...state.currentComment, ...payload };
        }
    },
    [CaseMutations.UpdateComment](state, payload: Comment): void {
        let comment = state.case?.comments.find((x) => x.id === payload.id);
        if (comment) {
            comment = Object.assign(comment, payload);
        }
    },
    [CaseMutations.RemoveComment](state, payload: number) {
        const index = state.case?.comments.map((x) => x.id).indexOf(payload);
        // Need manually to check for undefined cause "0" index equals to false
        if (index != undefined && index >= 0) {
            state.case?.comments.splice(index, 1);
        }
    },
    [CaseMutations.AddComment](state, payload: Comment): void {
        if (state.case?.comments) {
            state.case.comments.unshift(payload);
        }
    },
    [CaseMutations.AddHistoryItem](state, payload: HistoryItem): void {
        if (state.case?.historyItems) {
            state.case.historyItems.unshift(payload);
        }
    }
};

export default mutations;
