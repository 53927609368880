import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { NonUserState } from ".";
import { AccessEmail } from "@/models/nonUser/AccessEmail";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";

export enum NonUserGetters {
    AccessEmail = "accessEmail",
    Loading = "loading",
    Errors = "errors",
    Case = "case",
    CurrentComment = "currentComment"
}

const getters: GetterTree<NonUserState, RootState> = {
    [NonUserGetters.AccessEmail](state): AccessEmail | null {
        return state.accessEmail;
    },
    [NonUserGetters.Loading](state): boolean {
        return state.loading;
    },
    [NonUserGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [NonUserGetters.Case](state): Partial<Case> | null {
        return state.case;
    },
    [NonUserGetters.CurrentComment](state): Comment | null {
        return state.currentComment;
    }
};

export default getters;
