
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { emailRules } from "@/services/validationUtils";

@Component({
    data() {
        return {
            emailRules
        };
    }
})
export default class EmailEditor extends Vue {
    @Prop({ type: Object, required: true })
    public emailWrapper!: { email: string };

    protected valid = false;

    get email(): string {
        return this.emailWrapper.email;
    }
    set email(value: string) {
        this.emailWrapper.email = value;
    }

    resetValidation(): void {
        (
            this.$refs.emailEditorForm as Vue & { resetValidation: () => void }
        ).resetValidation();
    }

    submit(): void {
        (
            this.$refs.emailEditorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.$emit("submit", this.email);
        }
    }
}
