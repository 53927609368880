
import { Department } from "@/models/department/Department";
import { Tag, TagType } from "@/models/department/Tag";
import TagChip from "@/components/Tag/TagChip";
import Vue from "vue";

import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { User } from "@/models/User";
import { getUserFullName, checkIsUserDisabled } from "@/services/userUtils";
import { departmentRules, responsibleRules } from "@/services/validationUtils";
import UserAutocomplete from "@/components/Shared/UserAutocomplete";
import DepartmentAutocomplete from "@/components/Shared/DepartmentAutocomplete";

@Component({
    data() {
        return {
            departmentRules,
            responsibleRules
        };
    },
    components: {
        TagChip,
        UserAutocomplete,
        DepartmentAutocomplete
    },
    methods: {
        getUserFullName,
        checkIsUserDisabled
    }
})
export default class DepartmentUpdateDialog extends Vue {
    @PropSync("showDialog", { type: Boolean, required: true })
    private showDialogSync!: boolean;

    @Prop({ type: Array, required: true })
    private departments!: Department[];

    @Prop({ type: Number, required: true })
    private selectedDepartmentId!: number;

    @Prop({ type: Number, required: true })
    private selectedResponsibleId!: number | null;

    @Prop({ type: Array, required: true })
    private selectedTopics!: Tag[];

    @Prop({ type: Array, required: true })
    private selectedAreas!: Tag[];

    protected valid = false;
    protected selectedDepartmentIdLocal: number | null = null;
    protected selectedResponsibleIdLocal: number | null = null;
    protected selectedTopicsLocal: Tag[] = [];
    protected selectedAreasLocal: Tag[] = [];

    get users(): User[] {
        if (this.selectedDepartmentIdLocal)
            return (
                this.departments
                    .find((el) => el.id == this.selectedDepartmentIdLocal)
                    ?.members.filter(
                        (user) =>
                            user.isEnabled ||
                            user.id == this.selectedResponsibleId
                    ) ?? []
            );
        return [];
    }

    get selectDepartments(): Department[] {
        return this.departments.filter(
            (dep) => dep.isEnabled || dep.id == this.selectedDepartmentIdLocal
        );
    }

    get topics(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Topic)) ?? [];
    }

    get areas(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Area)) ?? [];
    }

    get isCurrentDepartmentDeactivated(): boolean {
        const department = this.departments.find(
            (el) => el.id == this.selectedDepartmentIdLocal
        );
        if (department) return !department.isEnabled;
        return false;
    }

    created(): void {
        this.resetLocal();
    }

    @Watch("showDialogSync")
    handleChnage(): void {
        this.resetLocal();
    }

    @Watch("selectedDepartmentIdLocal")
    reset(_: number, oldVal: number | null): void {
        if (oldVal) {
            this.selectedResponsibleIdLocal = null;
            this.selectedTopicsLocal = [];
            this.selectedAreasLocal = [];
        }
    }

    getTagsByType(type: TagType): Tag[][] {
        return this.departments
            .filter((el) =>
                el.id ? this.selectedDepartmentIdLocal == el.id : false
            )
            .map((dep) => dep.tags.filter((tag) => tag.type == type));
    }

    tagMatrixToArray(matrix: Tag[][]): Tag[] {
        let tags = [] as Tag[];
        for (let i = 0; i < matrix.length; i++) {
            tags = tags.concat(matrix[i]);
        }
        return tags;
    }

    resetLocal(): void {
        this.selectedDepartmentIdLocal = this.selectedDepartmentId;
        this.selectedResponsibleIdLocal = this.selectedResponsibleId;
        this.selectedTopicsLocal = this.selectedTopics;
        this.selectedAreasLocal = this.selectedAreas;
    }

    save(): void {
        (
            this.$refs.departmentUpdateDialog as Vue & {
                validate: () => boolean;
            }
        ).validate();
        if (this.valid) {
            this.showDialogSync = false;
            this.$emit("save", {
                department: this.selectedDepartmentIdLocal,
                responsible: this.selectedResponsibleIdLocal,
                topic: this.selectedTopicsLocal,
                area: this.selectedAreasLocal
            });
        }
    }
}
