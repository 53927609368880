
import Vue from "vue";
import { Component, PropSync } from "vue-property-decorator";
import InfoPopup from "@/components/Shared/InfoPopup";

@Component({
    components: {
        InfoPopup
    }
})
export default class TermsOfUsePopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;
}
