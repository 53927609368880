
import playStoreLogo from "@/assets/img/play_store.png";
import appStoreLogo from "@/assets/img/app_store.png";
import Component from "vue-class-component";
import Vue from "vue";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import { AccountMutations } from "@/store/modules/account/mutations";
import Errors from "@/constants/BackendResponseErrors.json";
import { Routes } from "@/router/routes";
import ErrorNotification from "@/components/Shared/ErrorNotification";

@Component({
    components: { ErrorNotification },
    methods: {
        ...accountModule.mapActions({
            getTwoFactorSetupCode: AccountActions.GetTwoFactorSetupCode,
            activateTwoFactor: AccountActions.ActivateTwoFactorAuthentication,
            verifyLogin: AccountActions.VerifyLogin
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...accountModule.mapGetters({
            getErrors: AccountGetters.Errors
        })
    }
})
export default class TwoFactorActivation extends Vue {
    private appStoreLogo: string = appStoreLogo;
    private playStoreLogo: string = playStoreLogo;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: () => string[] | null;
    private readonly getTwoFactorSetupCode!: (
        payload: string
    ) => Promise<string>;
    private readonly activateTwoFactor!: (payload: {
        token: string;
        code: string;
    }) => Promise<void>;
    private readonly verifyLogin!: () => Promise<void>;

    protected valid = false;
    protected showPassword = false;

    protected notificationMessage = "";
    protected notificationVisible = false;

    protected tokenErrorPopupVisible = false;
    protected successPopupVisible = false;

    protected codeRules = [
        (v: string): boolean | string => {
            return !!v || "Code is required";
        },
        (v: string): boolean | string => {
            return /^(\d{6})$/.test(v) || "Code is has to be 6 digits.";
        }
    ];

    protected twoFactorModel = {
        twoFactorPassword: ""
    };

    get twoFactorPassword(): string {
        return this.twoFactorModel.twoFactorPassword;
    }

    set twoFactorPassword(twoFactorPassword: string) {
        this.twoFactorModel.twoFactorPassword = twoFactorPassword;
    }

    mounted(): void {
        this.getQrCodeData().then((data) => {
            const sessionErrors = this.getErrors();
            this.setErrors(null);

            if (sessionErrors !== undefined && sessionErrors !== null) {
                this.tokenErrorPopupVisible = true;
            } else {
                const qrCodeImageElement = document.getElementById(
                    "qrCode"
                ) as HTMLImageElement;
                qrCodeImageElement.src = data;
            }
        });
    }

    redirectToHome(): void {
        this.$router.push(
            (this.$route.query.redirecturl as string) ?? Routes.Cases
        );
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }

    async submit(): Promise<void> {
        (
            this.$refs.twoFactorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            const token = this.$route.query.token as string;
            await this.activateTwoFactor({
                token: token,
                code: this.twoFactorModel.twoFactorPassword
            });
            const sessionErrors = this.getErrors();
            this.setErrors(null);

            if (sessionErrors !== undefined && sessionErrors !== null) {
                if (sessionErrors[0].includes(Errors.BadRequest.Error)) {
                    this.showNotification("Ungültiger Sicherheitscode.");
                } else {
                    this.showNotification(
                        sessionErrors[1] ?? Errors.Default.Message
                    );
                }
            } else {
                await this.verifyLogin();
                this.successPopupVisible = true;
            }
        }
    }

    private async getQrCodeData(): Promise<string> {
        const token = this.$route.query.token as string;
        const data = await this.getTwoFactorSetupCode(token);
        return data;
    }
}
