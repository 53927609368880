
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import { Department } from "@/models/department/Department";

@Component({})
export default class DepartmentAutocomplete extends Vue {
    @PropSync("departmentId", { required: true })
    protected departmentIdSync!: number | null;

    @Prop({ type: Array, required: true })
    protected departments!: Department[];

    @Prop({ type: String, default: "" })
    protected label!: string;

    @Prop({ type: Array, default: () => [] })
    protected rules!: (() => boolean | string)[];

    @Prop({ type: Boolean, default: true })
    protected outlined!: boolean;

    @Prop({ type: Boolean, default: false })
    protected hideDetails!: boolean;

    @Prop({ type: Boolean, default: false })
    protected clearable!: boolean;

    @Prop({ type: Boolean, default: false })
    protected chips!: boolean;

    isDepartmentDeactivated(department: Department): boolean {
        return !department.isEnabled;
    }
}
