
import { Tag, TagType } from "@/models/department/Tag";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TagChip extends Vue {
    @Prop({ type: Object, required: true })
    public readonly tag!: Tag;

    @Prop({ type: Boolean, default: true })
    public readonly closable!: boolean;

    get isOutlined(): boolean {
        return this.tag.type == TagType.Area;
    }

    get textColor(): string | undefined {
        return this.tag.type == TagType.Topic ? "white" : undefined;
    }

    editTag(tag: Tag): void {
        this.$emit("editTag", tag);
    }

    deleteTag(tag: Tag): void {
        this.$emit("deleteTag", tag);
    }
}
