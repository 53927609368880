import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { Department } from "@/models/department/Department";

export interface DepartmentState {
    departments: Department[];
    totalDepartments: number | null;
    department: Department | null;
    errors: string[] | null;
    loading: boolean;
    isEdited: boolean;
}

const caseState: DepartmentState = {
    departments: [],
    totalDepartments: null,
    department: null,
    errors: null,
    loading: false,
    isEdited: false
};

const department: Module<DepartmentState, RootState> = {
    namespaced: true,
    state: caseState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const departmentModule = createNamespacedHelpers("department");

export default department;
