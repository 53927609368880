
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component({})
export default class ErrorNotification extends Vue {
    @PropSync("isVisible", { type: Boolean, required: true })
    public readonly syncedIsVisible!: string;

    @Prop({ type: String, default: "Unknown error" })
    public readonly message!: string;

    @Prop({ type: Number, required: true })
    public readonly timeout!: number;

    updateVisible(): void {
        this.$emit("update:isVisible", !this.syncedIsVisible);
    }
}
