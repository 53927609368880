
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { CaseMutations } from "@/store/modules/case/mutations";
import { Case, CaseStatus, CaseType, CaseUrgency } from "@/models/case/Case";
import { Role, User } from "@/models/User";
import { accountModule } from "@/store/modules/account";
import { AccountGetters } from "@/store/modules/account/getters";
import { userModule } from "@/store/modules/user";
import { UserActions } from "@/store/modules/user/actions";
import { departmentModule } from "@/store/modules/department";
import { DepartmentActions } from "@/store/modules/department/actions";
import { Department } from "@/models/department/Department";
import { DepartmentGetters } from "@/store/modules/department/getters";
import { Tag, TagType } from "@/models/department/Tag";
import { Routes } from "@/router/routes";
import { Session } from "@/models/Session";
import { Event, EventType } from "@/models/case/Event";
import SelectBoxViewWidget from "@/components/Shared/SelectBoxViewWidget";
import TextBoxViewWidget from "@/components/Shared/TextBoxViewWidget";
import TextAreaViewWidget from "@/components/Shared/TextAreaViewWidget";
import DateViewWidget from "@/components/Shared/DateViewWidget";
import CommentViewWidget from "@/components/Case/CommentViewWidget";
import CommentEditor from "@/components/Case/CommentEditor";
import HistoryItemLabel from "@/components/Case/HistoryItemLabel";
import TagChip from "@/components/Tag/TagChip";
import VerticalExpansionPanelWrapper from "@/components/Shared/VerticalExpansionPanelWrapper";
import DepartmentUpdateDialog from "@/components/Case/DepartmentUpdateDialog";
import EmailSendingDialog from "@/components/Shared/EmailSendingDialog";
import FileUploadErrorPopup from "@/components/Shared/FileUploadErrorPopup";
import UserAutocomplete from "@/components/Shared/UserAutocomplete";
import InfoPopup from "@/components/Shared/InfoPopup";
import {
    isUserInRole,
    getUserFullName,
    getUserInitials,
    getCurrentUser,
    checkIsUserDisabled
} from "@/services/userUtils";
import { stringEnumToArray } from "@/services/enumUtils";
import {
    titleRules,
    descriptionRules,
    salesforceIdRules,
    customerNameRules,
    responsibleRules
} from "@/services/validationUtils";

@Component({
    data() {
        return {
            titleRules,
            descriptionRules,
            salesforceIdRules,
            customerNameRules,
            responsibleRules
        };
    },
    components: {
        SelectBoxViewWidget,
        TextBoxViewWidget,
        DateViewWidget,
        CommentViewWidget,
        CommentEditor,
        TextAreaViewWidget,
        HistoryItemLabel,
        VerticalExpansionPanelWrapper,
        DepartmentUpdateDialog,
        EmailSendingDialog,
        FileUploadErrorPopup,
        InfoPopup,
        TagChip,
        UserAutocomplete
    },
    computed: {
        ...caseModule.mapGetters({
            caseItem: CaseGetters.Case,
            errors: CaseGetters.Errors
        }),
        ...accountModule.mapGetters({
            session: AccountGetters.Session
        }),
        ...departmentModule.mapGetters({
            departments: DepartmentGetters.Departments
        })
    },
    methods: {
        ...caseModule.mapActions({
            saveCase: CaseActions.SaveCase,
            saveCurrentComment: CaseActions.UpdateComment,
            loadCase: CaseActions.LoadCase,
            createComment: CaseActions.CreateComment,
            addNewCurrentComment: CaseActions.AddNewCurrentComment,
            downloadAttachment: CaseActions.DownloadAttachment
        }),
        ...caseModule.mapMutations({
            updateCase: CaseMutations.UpdateCase,
            addAttachments: CaseMutations.AddAttachments,
            removeAttachment: CaseMutations.RemoveAttachment
        }),
        ...userModule.mapActions({
            loadUsersByIds: UserActions.LoadUsersByIds
        }),
        ...departmentModule.mapActions({
            loadDepartments: DepartmentActions.LoadDepartments
        }),
        getUserFullName,
        getUserInitials,
        getCurrentUser,
        checkIsUserDisabled
    }
})
export default class ViewCase extends Vue {
    private caseItem!: Case;
    private session!: Session;
    protected readonly departments!: Department[];

    private readonly updateCase!: (payload: Partial<Case>) => void;
    private readonly saveCase!: () => Promise<boolean>;
    private readonly addNewCurrentComment!: () => Promise<void>;
    private readonly createComment!: () => Promise<void>;
    private readonly loadUsersByIds!: (ids: Array<number>) => Promise<void>;
    private readonly loadCase!: (caseId: number) => Promise<void>;
    private readonly loadDepartments!: () => Promise<void>;
    private readonly addAttachments!: (files: File[]) => void;
    private readonly saveCurrentComment!: () => void;
    private readonly removeAttachment!: (id: number) => void;
    private readonly downloadAttachment!: ({
        attachmentId,
        caseId
    }: {
        attachmentId: number;
        caseId: number;
    }) => void;

    protected readonly statuses = stringEnumToArray(CaseStatus);
    protected readonly types = stringEnumToArray(CaseType);
    protected readonly urgencies = stringEnumToArray(CaseUrgency);

    protected readonly errors!: string[];

    private allowedFileTypes = [
        "message/rfc822", // .eml file
        "application/vnd.ms-outlook", // .msg file
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" // .pptx
    ];
    protected junkFiles = [] as string[];

    protected showCommentEditor = false;
    protected showDepartmentDialog = false;
    protected showEmailDialog = false;
    protected showFileUploadErrorDialog = false;
    protected showResponsibleSubstitutedDialog = false;

    protected panel1 = 0;
    protected panel2 = 0;

    protected skeletonLoader = true;

    async created(): Promise<void> {
        if (this.caseId !== null) {
            await this.loadDepartments();
            await this.loadCase(this.caseId);
            await this.loadHistoryItemUsers();
            this.skeletonLoader = false;
        } else {
            this.$router.push(Routes.Cases);
        }
    }

    get title(): string {
        return this.caseItem?.title ?? "";
    }
    set title(value: string) {
        this.updateCase({ title: value });
    }

    get description(): string {
        return this.caseItem?.description ?? "";
    }
    set description(value: string) {
        this.updateCase({ description: value });
    }

    get status(): CaseStatus | null {
        if (this.caseItem?.status) return this.caseItem.status;
        return null;
    }
    set status(value: CaseStatus | null) {
        if (value) {
            this.updateCase({ status: value });
        }
    }

    get listStatuses(): { name: string; disabled: boolean }[] {
        const listStatuses = [] as { name: string; disabled: boolean }[];
        this.statuses.forEach((status) => {
            switch (status) {
                case CaseStatus.Information:
                    listStatuses.push({
                        name: status,
                        disabled: !this.isUserSU && !this.isUserAdmin
                    });
                    break;
                default:
                    listStatuses.push({
                        name: status,
                        disabled: false
                    });
                    break;
            }
        });
        return listStatuses;
    }

    get type(): CaseType | null {
        if (this.caseItem?.type) return this.caseItem.type;
        return null;
    }
    set type(value: CaseType | null) {
        if (value) {
            this.updateCase({ type: value });
        }
    }

    get urgency(): CaseUrgency | null {
        if (this.caseItem?.urgency) return this.caseItem.urgency;
        return null;
    }
    set urgency(value: CaseUrgency | null) {
        if (value) {
            this.updateCase({ urgency: value });
        }
    }

    get listUrgencies(): { name: string; disabled: boolean }[] {
        if (isUserInRole(Role.Admin))
            return this.urgencies.map((u) => ({ name: u, disabled: false }));

        const currentUrgencyIndex = this.urgency
            ? this.urgencies.indexOf(this.urgency)
            : -1;
        return this.urgencies.map((u, index) => ({
            name: u,
            disabled: currentUrgencyIndex > index
        }));
    }

    get customerName(): string {
        return this.caseItem?.customerName ?? "";
    }
    set customerName(value: string) {
        this.updateCase({ customerName: value });
    }

    get activeReminder(): boolean {
        return this.caseItem?.activeReminder;
    }
    set activeReminder(value: boolean) {
        this.updateCase({ activeReminder: value });
    }

    get salesforceId(): string {
        return this.caseItem?.salesforceId ?? "";
    }
    set salesforceId(value: string) {
        this.updateCase({ salesforceId: value });
    }

    get users(): User[] {
        if (this.department)
            return (
                this.departments
                    .find((el) => el.id == this.department)
                    ?.members.filter(
                        (user) => user.isEnabled || user.id == this.responsible
                    ) ?? []
            );
        return [];
    }

    get responsible(): number | null {
        if (this.caseItem?.responsibleId) return this.caseItem.responsibleId;
        return null;
    }
    set responsible(value: number | null) {
        if (value) {
            this.updateCase({ responsibleId: value });
        }
    }

    get author(): number | null {
        if (this.caseItem?.authorId) return this.caseItem.authorId;
        return null;
    }
    set author(value: number | null) {
        if (value) {
            this.updateCase({ authorId: value });
        }
    }

    get department(): number | null {
        if (this.caseItem?.departmentId) return this.caseItem.departmentId;
        return null;
    }

    set department(value: number | null) {
        if (value) {
            this.updateCase({ departmentId: value });
        }
    }

    get receivingDate(): Date | null {
        if (this.caseItem?.receivingDate) return this.caseItem.receivingDate;
        return null;
    }

    set receivingDate(value: Date | null) {
        if (value) {
            this.updateCase({ receivingDate: value });
        }
    }

    get topics(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Topic)) ?? [];
    }

    get topic(): Tag[] {
        return (
            this.caseItem?.tags?.filter((el) => el.type == TagType.Topic) ?? []
        );
    }

    set topic(value: Tag[]) {
        const newTags = this.getNewTags(value, TagType.Topic);
        this.updateCase({ tags: newTags });
    }

    get areas(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Area)) ?? [];
    }

    get area(): Tag[] {
        return (
            this.caseItem?.tags?.filter((el) => el.type == TagType.Area) ?? []
        );
    }

    set area(value: Tag[]) {
        const newTags = this.getNewTags(value, TagType.Area);
        this.updateCase({ tags: newTags });
    }

    set attachments(files: File[]) {
        if (files?.length > 0) {
            this.junkFiles = [];
            for (let i = 0; i < files.length; i++) {
                if (files[i].name.endsWith(".msg")) {
                    const file = new File([files[i]], files[i].name, {
                        type: "application/vnd.ms-outlook"
                    });
                    files[i] = file;
                }
                if (
                    files[i].size > 10000000 ||
                    !this.allowedFileTypes.includes(files[i].type)
                ) {
                    this.showFileUploadErrorDialog = true;
                    this.junkFiles.push(files[i].name);
                    files.splice(i, 1);
                }
            }
            this.addAttachments(files);
            this.saveCase().then(() => {
                if (this.caseId !== null) this.loadCase(this.caseId);
            });
            (
                this.$refs.fileInput as Vue & {
                    value: unknown;
                }
            ).value = [];
        }
    }

    //This is needed just to prevent error in console
    get attachments(): File[] {
        return [];
    }

    get caseId(): number | null {
        const id = this.$router.currentRoute.params.id;
        const parsedId = Number(id);
        return isNaN(parsedId) ? null : parsedId;
    }

    get isCaseErasured(): boolean {
        return this.caseItem?.isErasured;
    }

    get isUserAuthor(): boolean {
        return this.session?.User?.id == this.caseItem.authorId;
    }

    get isNewlyCreated(): boolean {
        const msBetweenDates = Math.abs(
            new Date(this.caseItem.creationDate).getTime() -
                new Date().getTime()
        );

        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
        return hoursBetweenDates < 24;
    }

    get isUserSU(): boolean {
        return isUserInRole(Role.SU);
    }

    get isUserAdmin(): boolean {
        return isUserInRole(Role.Admin);
    }

    get isCurrentDepartmentDeactivated(): boolean {
        const department = this.departments.find(
            (el) => el.id == this.department
        );
        if (department) return !department.isEnabled;
        return false;
    }

    get timelineItems(): Event[] {
        return [...this.caseItem.comments, ...this.caseItem.historyItems].sort(
            (a, b) => {
                return (
                    Number(new Date(b.creationDate)) -
                    Number(new Date(a.creationDate))
                );
            }
        );
    }

    @Watch("caseItem")
    async saveCaseItem(value: Case, oldVal: Case): Promise<void> {
        if (oldVal) {
            //We need this extra variable to prevent from endless update, as this.showResponsibleSubstitutedDialog will not go to false after loadCase()
            let isNeededToReloadCase = false;
            const result = await this.saveCase();
            if (result) {
                this.showResponsibleSubstitutedDialog = true;
                isNeededToReloadCase = true;
            }
            if (isNeededToReloadCase && this.caseId)
                await this.loadCase(this.caseId);
            await this.loadHistoryItemUsers();
        }
    }

    setDepartment(payload: {
        department: number;
        responsible: number;
        topic: Tag[];
        area: Tag[];
    }): void {
        this.updateCase({
            departmentId: payload.department,
            responsibleId: payload.responsible,
            tags: [...payload.topic, ...payload.area]
        });
    }

    getTimelineItemIconColor(item: Event): string {
        if (!item.author) return "grey darken-1";

        if (item.author.email.endsWith("@gkk.de")) return "orange darken-1";

        if (item.author.email.endsWith("@bmw.de")) return "indigo darken-1";

        return "teal darken-1";
    }

    isEventComment(event: Event): boolean {
        return event.type == EventType.Comment;
    }

    getUserFullNameById(id?: number): string {
        const user = this.users.filter((el) => el.id === id)[0];
        return getUserFullName(user);
    }

    getDate(date: Date | null): string {
        if (date) return new Date(date).toLocaleDateString();
        return "No data";
    }

    getFullDate(date: Date | null): string {
        if (date) return new Date(date).toLocaleString();
        return "No data";
    }

    urgencyColor(urgency: CaseUrgency): string {
        switch (urgency) {
            case CaseUrgency.High:
                return "red";
            case CaseUrgency.Medium:
                return "orange";
            case CaseUrgency.Low:
                return "indigo";
        }
    }

    urgencyText(urgency: CaseUrgency): string {
        switch (urgency) {
            case CaseUrgency.High:
                return "Höchste Priorität";
            case CaseUrgency.Medium:
                return "Mittel Priorität";
            case CaseUrgency.Low:
                return "Niedrig Priorität";
        }
    }

    showDepartmentDialogModal(): void {
        if (!this.isCaseErasured) this.showDepartmentDialog = true;
    }

    getCaseAge(caseItem?: Case): string {
        if (caseItem?.creationDate) {
            const dateNow = new Date();
            const creationDate = new Date(caseItem.creationDate);

            let minutes = Math.floor(
                Math.floor(
                    (dateNow.getTime() - creationDate.getTime()) / 1000
                ) / 60
            );
            let hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            hours = hours - days * 24;
            minutes = minutes - days * 24 * 60 - hours * 60;
            return `${days > 0 ? days + (days > 1 ? " Tage" : " Tag") : ""} ${
                hours > 0 ? hours + (hours > 1 ? " Stunden" : " Stunde") : ""
            } ${minutes + (minutes > 1 ? " Minuten" : " Minute")}`;
        }
        return "";
    }

    getNewTags(value: Tag[], type: TagType): Tag[] {
        const tags = this.caseItem?.tags?.filter(
            (el) =>
                el.type == (type == TagType.Area ? TagType.Topic : TagType.Area)
        );
        return [...(tags ?? []), ...value];
    }

    getTagsByType(type: TagType): Tag[][] {
        return this.departments
            .filter((el) => (el.id ? this.department == el.id : false))
            .map((dep) => dep.tags.filter((tag) => tag.type == type));
    }

    tagMatrixToArray(matrix: Tag[][]): Tag[] {
        let tags = [] as Tag[];
        for (let i = 0; i < matrix.length; i++) {
            tags = tags.concat(matrix[i]);
        }
        return tags;
    }

    async loadHistoryItemUsers(): Promise<void> {
        const responsibleHistoryItems = this.caseItem.historyItems.filter(
            (el) => el.propertyName == "ResponsibleId"
        );
        let ids = responsibleHistoryItems.map((el) =>
            Number(el.newPropertyValue)
        );
        ids = ids.concat(
            responsibleHistoryItems.map((el) => Number(el.oldPropertyValue))
        );
        await this.loadUsersByIds([...new Set(ids)]);
    }

    addComment(): void {
        this.addNewCurrentComment();
        this.showCommentEditor = true;
    }

    async createNewComment(): Promise<void> {
        await this.createComment();
        this.showCommentEditor = false;
    }

    async removeCaseAttachment(id: number): Promise<void> {
        await this.removeAttachment(id);
        await this.saveCase();
        if (this.caseId) await this.loadCase(this.caseId);
    }

    async downloadCaseAttachment(attachmentId: number): Promise<void> {
        if (this.caseId)
            await this.downloadAttachment({
                attachmentId,
                caseId: this.caseId
            });
    }

    async saveComment(): Promise<void> {
        await this.saveCurrentComment();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    addDropFile(e: any): void {
        this.attachments = Array.from(e.dataTransfer.files);
    }
}
