
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class DateViewWidget extends Vue {
    @Prop({ type: String, required: false })
    public date!: string | Date | undefined;

    @Prop({ type: String, default: "100%" })
    private width!: string;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    protected isDisplayMode = true;
    protected dateMenu = false;

    get value(): string {
        if (this.date) {
            let strValue: string;
            if (this.date instanceof Date) {
                strValue = this.date.toISOString();
            } else {
                strValue = this.date;
            }
            if (this.date) {
                return strValue.substring(0, 10);
            }
        }
        return "";
    }
    set value(value: string) {
        this.$emit("update:date", value.substring(0, 10));
    }

    get displayValue(): string {
        if (this.date) {
            return new Date(this.date).toLocaleDateString();
        }
        return "";
    }

    changeMode(): void {
        if (!this.disabled && !this.dateMenu) {
            this.isDisplayMode = !this.isDisplayMode;
        }
    }
}
