import userApi from "@/api/userApi";
import userSubstituteApi from "@/api/userSubstituteApi";
import { RootState } from "@/store";
import { ActionContext, ActionTree, Commit } from "vuex";
import { SubstituteState } from ".";
import { SubstituteMutations } from "./mutations";
import { makeRequest } from "@/services/requestUtils";
import { getErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import { getCurrentUser } from "@/services/userUtils";
import { AccountMutations } from "@/store/modules/account/mutations";
import { UserSubstitute } from "@/models/User";

export enum SubstituteActions {
    LoadUsers = "loadUsers",
    LoadUserSubstitutes = "loadUserSubstitutes",
    CreateUserSubstitute = "createUserSubstitute",
    UpdateUserSubstitute = "updateUserSubstitute",
    DeleteUserSubstitute = "deleteUserSubstitute"
}

async function makeSubstituteRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        SubstituteMutations.SetErrors,
        SubstituteMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<SubstituteState, RootState> = {
    async [SubstituteActions.LoadUsers](context): Promise<void> {
        if (context.state.users.length) return;
        await makeSubstituteRequest(
            context.commit,
            () => userApi.getUsers(),
            (result) => {
                context.commit(SubstituteMutations.SetUsers, result);
            }
        );
    },
    async [SubstituteActions.LoadUserSubstitutes](context): Promise<void> {
        const userId = context.state.userId;
        if (!userId) return;

        await makeSubstituteRequest(
            context.commit,
            () => userSubstituteApi.listUserSubstitutes(userId),
            (result) => {
                context.commit(SubstituteMutations.SetUserSubstitutes, result);
            }
        );
    },
    async [SubstituteActions.CreateUserSubstitute]({
        state,
        commit
    }): Promise<void> {
        const userId = state.userId;
        if (!userId) return;

        const userSubstitute = state.editingSubstitute;
        await makeSubstituteRequest(
            commit,
            () =>
                userSubstituteApi.createUserSubstitute(
                    userId,
                    userSubstitute as UserSubstitute
                ),
            (result) => {
                if (
                    !state.userSubstitutes.some(
                        (s) => s.userSubstituteId == result.userSubstituteId
                    )
                ) {
                    commit(SubstituteMutations.SetUserSubstitutes, [
                        ...state.userSubstitutes,
                        result
                    ]);
                }

                if (getCurrentUser()?.id == state.userId)
                    commit(
                        "account/" + AccountMutations.SetSessionUserSubstitutes,
                        [...state.userSubstitutes],
                        {
                            root: true
                        }
                    );
            }
        );
    },
    async [SubstituteActions.UpdateUserSubstitute]({
        state,
        commit,
        getters
    }): Promise<void> {
        const { editingSubstitute } = state;
        const { userId } = getters;
        if (!userId || !editingSubstitute.userSubstituteId) return;

        const userSubstituteId = editingSubstitute.userSubstituteId;
        await makeSubstituteRequest(
            commit,
            () =>
                userSubstituteApi.updateUserSubstitute(
                    userId,
                    userSubstituteId,
                    editingSubstitute as UserSubstitute
                ),
            (result) => {
                const foundSubsituteIndex = state.userSubstitutes.findIndex(
                    (s) => s.userSubstituteId == result.userSubstituteId
                );
                if (foundSubsituteIndex >= 0) {
                    const newUserSubsitutes = [...state.userSubstitutes];
                    newUserSubsitutes[foundSubsituteIndex] = result;
                    commit(
                        SubstituteMutations.SetUserSubstitutes,
                        newUserSubsitutes
                    );
                }

                if (getCurrentUser()?.id == userId)
                    commit(
                        "account/" + AccountMutations.SetSessionUserSubstitutes,
                        [...state.userSubstitutes],
                        {
                            root: true
                        }
                    );
            }
        );
    },
    async [SubstituteActions.DeleteUserSubstitute](
        { state, commit },
        userSubstituteId
    ): Promise<void> {
        const userId = state.userId;
        if (!userId) return;

        await makeSubstituteRequest(
            commit,
            () =>
                userSubstituteApi.deleteUserSubstitute(
                    userId,
                    userSubstituteId
                ),
            () => {
                commit(
                    SubstituteMutations.DeleteUserSubstitute,
                    userSubstituteId
                );
                if (getCurrentUser()?.id == state.userId)
                    commit(
                        "account/" + AccountMutations.SetSessionUserSubstitutes,
                        [...state.userSubstitutes],
                        {
                            root: true
                        }
                    );
            }
        );
    }
};

export function handleReponseError(
    error: Error,
    context: ActionContext<SubstituteState, RootState>
): void {
    context.commit(
        SubstituteMutations.SetErrors,
        getErrorTextsFromErrorResponse(error)
    );
}

export default actions;
