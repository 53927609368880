import { User } from "../User";
import { Attachment } from "../Attachment";
import { Comment } from "./Comment";
import { HistoryItem } from "./HistoryItem";
import { Department } from "../department/Department";
import { Tag } from "../department/Tag";

export enum FullTextSearchType {
    And,
    Or,
    Phrase
}

export enum CaseStatus {
    New = "Neu",
    UnderProcessing = "In Bearbeitung",
    Solved = "Gelöst",
    Answer = "Antwort",
    OnHold = "On Hold",
    Information = "Information"
}

export enum CaseType {
    Request = "Anfrage",
    Information = "Information",
    Complaint = "Beschwerde",
    PraiseAndCriticism = "Lob & Kritik"
}

export enum CaseUrgency {
    Low = "Niedrig",
    Medium = "Mittel",
    High = "Hoch"
}

export interface Case {
    id?: number;
    title: string;
    description: string;
    status: CaseStatus;
    type: CaseType;
    authorId: number;
    author?: User;
    salesforceId: string;
    customerName: string;
    responsibleId: number;
    responsible?: User;
    receivingDate: Date;
    creationDate: Date;
    lastModifiedDate?: Date;
    urgency: CaseUrgency;
    isErasured: boolean;
    activeReminder: boolean;
    departmentId: number;
    department?: Department;
    tags: Tag[];
    comments: Comment[];
    historyItems: HistoryItem[];
    attachments: Attachment[];
}
