
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import { Prop, PropSync, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { departmentModule } from "@/store/modules/department";
import { DepartmentGetters } from "@/store/modules/department/getters";
import { Department } from "@/models/department/Department";
import {
    isUserInRole,
    getUserFullName,
    getUserInitials
} from "@/services/userUtils";
import { footerPageText } from "@/services/dataTableUtils";
import { Role } from "@/models/User";

@Component({
    computed: {
        ...departmentModule.mapGetters({
            departments: DepartmentGetters.Departments,
            totalDepartments: DepartmentGetters.TotalDepartments,
            loading: DepartmentGetters.Loading
        })
    },
    methods: {
        getUserFullName,
        getUserInitials,
        isUserInRole,
        footerPageText
    }
})
export default class DepartmentList extends Vue {
    @Prop({ type: Array, required: true })
    public readonly headers!: Array<DataTableHeader>;

    @PropSync("page", { type: Number })
    public syncPage!: number;

    protected readonly departments!: Department[];
    protected loading!: boolean;

    protected options = {};

    get isUserAdmin(): boolean {
        return isUserInRole(Role.Admin);
    }

    @Watch("options", { deep: true })
    updateOptions(): void {
        this.$emit("updateOptions", this.options);
    }

    editDepartment(department: Department): void {
        if (department?.id) {
            this.$router.push(Routes.EditDepartment + department.id);
        }
    }
}
