import { User } from "../User";
import { Case } from "./Case";

export enum EventType {
    History = 0,
    Comment = 1
}

export interface Event {
    id?: number;
    type: number;
    authorId: number;
    author?: User;
    caseId: number;
    case?: Case;
    creationDate: Date;
}
