
import Vue from "vue";
import AppIcon from "@/components/Shared/AppIcon";

const SideMenuItemProps = Vue.extend({
    components: { AppIcon },
    props: {
        icon: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        to: {
            type: String,
            required: false
        }
    }
});

export default class SideMenuItem extends SideMenuItemProps {
    get classes(): Array<string | null> {
        const path = this.$route.fullPath;
        return ["side-menu-link", path === this.to ? "active" : null];
    }
}
