
import { Role, User } from "@/models/User";
import { canUserManageRole } from "@/services/userUtils";
import { roleModule } from "@/store/modules/role";
import { RoleGetters } from "@/store/modules/role/getters";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { UserMutations } from "@/store/modules/user/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { emailRules } from "@/services/validationUtils";

@Component({
    data() {
        return {
            emailRules
        };
    },
    computed: {
        ...userModule.mapGetters({
            user: UserGetters.User
        }),
        ...roleModule.mapGetters({
            roles: RoleGetters.Roles
        })
    },
    methods: {
        ...userModule.mapMutations({
            updateUser: UserMutations.UpdateUser
        })
    }
})
export default class UserEditor extends Vue {
    @Prop({ type: Boolean, default: false })
    public readonly isCreatePage!: boolean;

    private readonly updateUser!: (user: Partial<User>) => void;

    protected readonly user!: Partial<User> | null;
    protected readonly roles!: string[];

    protected valid = false;

    protected firstNameRules = [
        (v: string): boolean | string => {
            return !!v || "Vorname ist ein Pflichtfeld";
        }
    ];

    protected lastNameRules = [
        (v: string): boolean | string => {
            return !!v || "Nachname ist ein Pflichtfeld";
        }
    ];

    protected roleRules = [
        (v: Role): boolean | string => {
            return !!v || "Rolle ist ein Pflichtfeld";
        }
    ];

    get availableRoles(): string[] {
        return this.roles.filter((r) => canUserManageRole(r));
    }

    get firstName(): string {
        return this.user?.firstName ?? "";
    }
    set firstName(value: string) {
        this.updateUser({ firstName: value });
    }

    get lastName(): string {
        return this.user?.lastName ?? "";
    }
    set lastName(value: string) {
        this.updateUser({ lastName: value });
    }

    get email(): string {
        return this.user?.email ?? "";
    }
    set email(value: string) {
        this.updateUser({ email: value });
    }

    get role(): Role | null {
        return this.user?.role ?? null;
    }
    set role(value: Role | null) {
        if (value) {
            this.updateUser({ role: value });
        }
    }

    get isEnabled(): boolean {
        return this.user?.isEnabled ?? false;
    }
    set isEnabled(value: boolean) {
        this.updateUser({ isEnabled: value });
    }

    submit(): void {
        (
            this.$refs.userEditorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.$emit("submit");
        }
    }
}
