
import Vue from "vue";
import AppFooter from "@/components/Layout/AppFooter";
import AppHeader from "@/components/Layout/AppHeader";

export default Vue.extend({
    components: {
        AppHeader,
        AppFooter
    }
});
