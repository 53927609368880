
import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { emailRules } from "@/services/validationUtils";
import { nonUserModule } from "@/store/modules/nonUser";
import { NonUserActions } from "@/store/modules/nonUser/actions";
import { AccessEmail } from "@/models/nonUser/AccessEmail";
import { NonUserMutations } from "@/store/modules/nonUser/mutations";
import { NonUserGetters } from "@/store/modules/nonUser/getters";
import ErrorNotification from "@/components/Shared/ErrorNotification";

@Component({
    components: {
        ErrorNotification
    },
    data() {
        return {
            emailRules
        };
    },
    computed: {
        ...nonUserModule.mapGetters({
            errors: NonUserGetters.Errors
        })
    },
    methods: {
        ...nonUserModule.mapActions({
            addAccessEmail: NonUserActions.AddAccessEmail,
            sendAccess: NonUserActions.SendAccess
        }),
        ...nonUserModule.mapMutations({
            setErrors: NonUserMutations.SetErrors
        })
    }
})
export default class EmailSendingDialog extends Vue {
    @PropSync("showDialog", { type: Boolean, required: true })
    private showDialogSync!: boolean;

    @Prop({ type: Number, required: true })
    private caseId!: number;

    private readonly addAccessEmail!: (
        accessEmail: AccessEmail
    ) => Promise<void>;
    private readonly sendAccess!: () => Promise<void>;
    private readonly setErrors!: (payload: string[] | null) => void;

    private readonly errors!: string[];

    protected notificationMessage = "";
    protected notificationVisible = false;

    protected carbonCopyEmailRules = [
        (v: string): boolean | string => {
            return v ? /.+@.+\..+/.test(v) || "Email ist falsch" : true;
        }
    ];

    protected recipientEmail = "";
    protected carbonCopyEmail = "";
    protected note = "";
    protected valid = false;

    @Watch("showDialogSync")
    reset(): void {
        this.recipientEmail = "";
        this.carbonCopyEmail = "";
        this.note = "";
    }

    async save(): Promise<void> {
        (
            this.$refs.emailSendDialog as Vue & {
                validate: () => boolean;
            }
        ).validate();
        if (this.valid) {
            await this.addAccessEmail({
                recipientEmail: this.recipientEmail,
                carbonCopyEmail: this.carbonCopyEmail,
                note: this.note,
                caseId: this.caseId
            } as AccessEmail);
            this.sendAccess().then(() => {
                const errors = this.errors;
                this.setErrors(null);
                if (errors !== undefined && errors !== null) {
                    this.showNotification(errors.join(". "));
                } else {
                    this.showDialogSync = false;
                }
            });
        }
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }
}
