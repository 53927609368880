
import { Department } from "@/models/department/Department";
import { Role, User } from "@/models/User";
import { departmentModule } from "@/store/modules/department";
import { DepartmentGetters } from "@/store/modules/department/getters";
import { DepartmentMutations } from "@/store/modules/department/mutations";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import {
    getUserFullName,
    isUserInRole,
    checkIsUserDisabled
} from "@/services/userUtils";
import Vue from "vue";
import Component from "vue-class-component";
import { Tag, TagType } from "@/models/department/Tag";
import TagEditor from "@/components/Tag/TagEditor";
import TagChip from "@/components/Tag/TagChip";
import EmailEditor from "@/components/Department/EmailEditor";
import { Prop } from "vue-property-decorator";

@Component({
    components: { TagEditor, EmailEditor, TagChip },
    computed: {
        ...departmentModule.mapGetters({
            department: DepartmentGetters.Department
        }),
        ...userModule.mapGetters({
            users: UserGetters.Users
        })
    },
    methods: {
        ...departmentModule.mapMutations({
            updateDepartment: DepartmentMutations.UpdateDepartment
        }),
        getUserFullName,
        checkIsUserDisabled
    }
})
export default class DepartmentEditor extends Vue {
    @Prop({ type: Boolean, default: false })
    public readonly isCreatePage!: boolean;

    private readonly updateDepartment!: (
        department: Partial<Department>
    ) => void;

    protected readonly users!: User[];
    protected readonly department!: Partial<Department> | null;

    protected tag = {} as Tag;
    protected isTagEdit = false;
    protected editableTag = {} as Tag;
    protected tagEditorDialog = false;

    protected emailWrapper = {};
    protected isEmailEdit = false;
    protected editableEmail = "";
    protected emailEditorDialog = false;

    protected valid = false;

    protected nameRules = [
        (v: string): boolean | string => {
            return !!v || "Name ist ein Pflichtfeld";
        },
        (v: string): boolean | string => {
            return (
                v.length <= 256 || "Name darf höchstens 256 Zeichen lang sein"
            );
        }
    ];

    protected membersRules = [
        (v: User[]): boolean | string => {
            return (
                v.length > 0 || "Ein Team sollte mindestens ein Mitglied haben"
            );
        }
    ];

    protected managersRules = [
        (v: User[]): boolean | string => {
            return (
                v.length > 0 || "Ein Team sollte mindestens einen Manager haben"
            );
        }
    ];

    get name(): string {
        return this.department?.name ?? "";
    }
    set name(value: string) {
        this.updateDepartment({ name: value });
    }

    get members(): User[] {
        return this.department?.members ?? [];
    }
    set members(value: User[]) {
        this.updateDepartment({ members: value });
    }

    get usersForMembers(): User[] {
        return (
            this.users.filter(
                (user) =>
                    user.isEnabled ||
                    this.department?.members
                        ?.map((user) => user.id)
                        .includes(user.id)
            ) ?? []
        );
    }

    get usersForManagers(): User[] {
        return (
            this.department?.members?.filter(
                (user) =>
                    user.isEnabled ||
                    this.department?.managers
                        ?.map((user) => user.id)
                        .includes(user.id)
            ) ?? []
        );
    }

    get managers(): User[] {
        return this.department?.managers ?? [];
    }
    set managers(value: User[]) {
        this.updateDepartment({ managers: value });
    }

    get emails(): string[] {
        return this.department?.emails ?? [];
    }

    get areas(): Tag[] {
        return (
            this.department?.tags?.filter((el) => el.type == TagType.Area) ?? []
        );
    }

    get topics(): Tag[] {
        return (
            this.department?.tags?.filter((el) => el.type == TagType.Topic) ??
            []
        );
    }

    get isEnabled(): boolean {
        return this.department?.isEnabled ?? false;
    }
    set isEnabled(value: boolean) {
        this.updateDepartment({ isEnabled: value });
    }

    get isUserAdmin(): boolean {
        return isUserInRole(Role.Admin);
    }

    createEmail(): void {
        this.emailWrapper = { email: "" };
        this.openEmailModal();
    }

    createTopic(): void {
        this.tag = { type: TagType.Topic } as Tag;
        this.openTagModal();
    }

    createArea(): void {
        this.tag = { type: TagType.Area } as Tag;
        this.openTagModal();
    }

    startEditingTag(tag: Tag): void {
        if (this.isUserAdmin) {
            this.tag = { ...tag };
            this.editableTag = tag;
            this.isTagEdit = true;
            this.openTagModal();
        }
    }

    startEditingEmail(email: string): void {
        if (this.isUserAdmin) {
            this.emailWrapper = { email };
            this.editableEmail = email;
            this.isEmailEdit = true;
            this.openEmailModal();
        }
    }

    openTagModal(): void {
        if (this.isUserAdmin) {
            this.tagEditorDialog = true;
            this.$nextTick(() => {
                const editor = this.isTagEdit
                    ? (this.$refs.tagEditEditor as Vue & {
                          resetValidation: () => void;
                      })
                    : (this.$refs.tagCreateEditor as Vue & {
                          resetValidation: () => void;
                      });
                editor.resetValidation();
            });
        }
    }

    openEmailModal(): void {
        if (this.isUserAdmin) {
            this.emailEditorDialog = true;
            this.$nextTick(() => {
                const editor = this.isEmailEdit
                    ? (this.$refs.emailEditEditor as Vue & {
                          resetValidation: () => void;
                      })
                    : (this.$refs.emailCreateEditor as Vue & {
                          resetValidation: () => void;
                      });
                editor.resetValidation();
            });
        }
    }

    addEmail(email: string): void {
        let newEmails = [] as string[];
        if (this.department) {
            if (this.department.emails) {
                newEmails = [...this.department.emails];
            }
            newEmails.push(email);
            this.updateDepartment({ emails: newEmails });
        }
        this.emailEditorDialog = false;
    }

    editEmail(email: string): void {
        let newEmails = [] as string[];
        if (this.department) {
            if (this.department.emails) {
                newEmails = [...this.department.emails];
            }
            newEmails[newEmails.indexOf(this.editableEmail)] = email;
            this.updateDepartment({ emails: newEmails });
        }
        this.emailEditorDialog = false;
        this.isEmailEdit = false;
    }

    deleteEmail(email: string): void {
        let emails = [] as string[];
        if (this.department) {
            if (this.department.emails) {
                emails = [...this.department.emails];
            }
            const newEmails = emails.filter(function (value) {
                return value != email;
            });
            this.updateDepartment({ emails: newEmails });
        }
    }

    addTag(tag: Tag): void {
        const newTag = { ...tag };
        let newTags = [] as Tag[];
        if (this.department) {
            if (this.department.tags) {
                newTags = [...this.department.tags];
            }
            newTags.push(newTag);
            this.updateDepartment({ tags: newTags });
        }
        this.tagEditorDialog = false;
    }

    editTag(tag: Tag): void {
        let newTags = [] as Tag[];
        if (this.department) {
            if (this.department.tags) {
                newTags = [...this.department.tags];
            }
            newTags[newTags.indexOf(this.editableTag)] = tag;
            this.updateDepartment({ tags: newTags });
        }
        this.tagEditorDialog = false;
        this.isTagEdit = false;
    }

    deleteTag(tag: Tag): void {
        let tags = [] as Tag[];
        if (this.department) {
            if (this.department.tags) {
                tags = [...this.department.tags];
            }
            const newTags = tags.filter(function (value) {
                return value != tag;
            });
            this.updateDepartment({ tags: newTags });
        }
    }

    submit(): void {
        (
            this.$refs.departmentEditorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.$emit("submit");
        }
    }
}
