
import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import { User, UserSubstitute } from "@/models/User";
import { substituteModule } from "@/store/modules/substitute";
import { SubstituteGetters } from "@/store/modules/substitute/getters";
import { getUserFullName, checkIsUserDisabled } from "@/services/userUtils";
import TimespanLabel from "@/components/Shared/TimespanLabel";
import ErrorNotification from "@/components/Shared/ErrorNotification";
import UserAutocomplete from "@/components/Shared/UserAutocomplete";
import { SubstituteMutations } from "@/store/modules/substitute/mutations";
import { SubstituteActions } from "@/store/modules/substitute/actions";

@Component({
    components: {
        TimespanLabel,
        ErrorNotification,
        UserAutocomplete
    },
    computed: {
        ...substituteModule.mapGetters({
            userId: SubstituteGetters.UserId,
            users: SubstituteGetters.Users,
            editingSubstitute: SubstituteGetters.EditingSubstitute,
            getErrors: SubstituteGetters.Errors
        })
    },
    methods: {
        ...substituteModule.mapMutations({
            updateEditingSubstitute:
                SubstituteMutations.UpdateEditingSubstitute,
            setErrors: SubstituteMutations.SetErrors
        }),
        ...substituteModule.mapActions({
            createUserSubstitute: SubstituteActions.CreateUserSubstitute,
            updateUserSubstitute: SubstituteActions.UpdateUserSubstitute
        }),
        getUserFullName,
        checkIsUserDisabled
    }
})
export default class SubstituteEditor extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    protected userId!: number | null;
    protected users!: User[];
    protected editingSubstitute!: Partial<UserSubstitute>;
    private readonly getErrors!: string[] | null;

    private readonly updateEditingSubstitute!: (
        payload: Partial<UserSubstitute>
    ) => void;
    private readonly setErrors!: (payload: string[] | null) => void;

    private readonly createUserSubstitute!: () => Promise<void>;
    private readonly updateUserSubstitute!: () => Promise<void>;

    protected valid = false;

    protected dateFromMenu = false;
    protected dateToMenu = false;

    protected notificationMessage = "";
    protected notificationVisible = false;

    dateFromRules = [
        (v: string): boolean | string => {
            return !!v || "Abwesenheit von ist ein Pflichtfeld";
        },
        (v: string): boolean | string => {
            const [day, month, year] = v.split(".");
            return (
                new Date(+year, +month - 1, +day) >=
                    new Date(new Date(Date.now()).toDateString()) ||
                "Abwesenheit von sollte größer oder gleich dem heutigen Datum sein"
            );
        }
    ];

    dateToRules = [
        (v: string): boolean | string => {
            return !!v || "Abwesenheit bis ist ein Pflichtfeld";
        },
        (v: string): boolean | string => {
            const [day, month, year] = v.split(".");
            return (
                new Date(+year, +month - 1, +day) >=
                    new Date(new Date(Date.now()).toDateString()) ||
                "Abwesenheit bis sollte größer oder gleich dem heutigen Datum sein"
            );
        },
        this.dateToRule
    ];

    substituteRules = [
        (v: User): boolean | string => {
            return !!v || "Vertreter ist ein Pflichtfeld";
        }
    ];

    get dateFrom(): string | null {
        if (this.editingSubstitute.dateFrom) {
            return this.editingSubstitute.dateFrom.substring(0, 10);
        }

        return null;
    }

    set dateFrom(value: string | null) {
        if (value) {
            this.updateEditingSubstitute({ dateFrom: value });
        }
    }

    get dateFromDisplayValue(): string {
        if (this.editingSubstitute.dateFrom)
            return this.displayValue(this.editingSubstitute.dateFrom);

        return "";
    }

    get dateTo(): string | null {
        if (this.editingSubstitute.dateTo) {
            return this.editingSubstitute.dateTo.substring(0, 10);
        }

        return null;
    }

    set dateTo(value: string | null) {
        if (value) {
            this.updateEditingSubstitute({ dateTo: value });
        }
    }

    get dateToDisplayValue(): string {
        if (this.editingSubstitute.dateTo)
            return this.displayValue(this.editingSubstitute.dateTo);

        return "";
    }

    get selectUsers(): User[] {
        return this.users.filter(
            (user) =>
                (user.isEnabled || user.id == this.substitute) &&
                user.id != this.userId
        );
    }

    get substitute(): number | null {
        return this.editingSubstitute.substituteId ?? null;
    }
    set substitute(value: number | null) {
        if (value) {
            this.updateEditingSubstitute({ substituteId: value });
        }
    }

    get minimunDatePickerDate(): string {
        return (
            this.pad(new Date(Date.now()).getFullYear()) +
            "-" +
            this.pad(new Date(Date.now()).getMonth() + 1) +
            "-" +
            this.pad(new Date(Date.now()).getDate())
        );
    }

    @Watch("popupVisible")
    async popupVisibleHandle(value: boolean, oldVal: boolean): Promise<void> {
        if (!oldVal && value) {
            if (this.$refs.substituteEditorForm)
                (
                    this.$refs.substituteEditorForm as Vue & {
                        resetValidation: () => boolean;
                    }
                ).resetValidation();
        }
    }

    pad(num: number): string {
        return num < 10 ? "0" + num : num.toString();
    }

    dateToRule(): boolean | string {
        if (!this.dateTo || !this.dateFrom) return true;

        return (
            new Date(this.dateTo) > new Date(this.dateFrom) ||
            "Abwesenheit bis muss größer als Abwesenheit von sein"
        );
    }

    displayValue(date: string): string {
        return new Date(date).toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        });
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }

    async cancel(): Promise<void> {
        this.popupVisible = false;
    }

    async save(): Promise<void> {
        await (
            this.$refs.substituteEditorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            if (this.editingSubstitute.userSubstituteId)
                await this.updateUserSubstitute();
            else await this.createUserSubstitute();

            const errors = this.getErrors;
            this.setErrors(null);
            if (errors !== undefined && errors !== null) {
                this.showNotification(errors.join(". "));
            } else {
                this.popupVisible = false;
            }
        }
    }
}
