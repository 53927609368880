
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import LoginFormData from "@/models/LoginFormData";
import { Prop } from "vue-property-decorator";
import { emailRules, passwordRules } from "@/services/validationUtils";

@Component({
    data() {
        return { emailRules, passwordRules };
    }
})
export default class CredentialsForm extends Vue {
    @Prop({ type: Object, required: true })
    public readonly loginData!: LoginFormData;

    protected passwordResetRoute = Routes.RequestPasswordReset;
    protected valid = false;
    protected showPassword = false;

    get email(): string {
        return this.loginData.email;
    }

    set email(emailAddress: string) {
        this.loginData.email = emailAddress;
    }

    get password(): string {
        return this.loginData.password;
    }

    set password(password: string) {
        this.loginData.password = password;
    }

    submit(): void {
        (this.$refs.loginForm as Vue & { validate: () => boolean }).validate();
        if (this.valid) {
            this.$emit("submit", this.loginData);
        }
    }
}
