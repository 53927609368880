import { LookupEntity } from "@/models/LookupEntity";
import { User } from "@/models/User";
import { RootState } from "@/store";
import { GetterTree } from "vuex";
import { UserState } from ".";

export enum UserGetters {
    Users = "users",
    TotalUsers = "totalUsers",
    LookupUsers = "lookupUsers",
    User = "user",
    Errors = "errors",
    Loading = "loading",
    IsEdited = "isEdited"
}

const getters: GetterTree<UserState, RootState> = {
    [UserGetters.Users](state): User[] {
        return state.users;
    },
    [UserGetters.TotalUsers](state): number | null {
        return state.totalUsers;
    },
    [UserGetters.User](state): Partial<User> | null {
        return state.user;
    },
    [UserGetters.Loading](state): boolean {
        return state.loading;
    },
    [UserGetters.Errors](state): string[] | null {
        return state.errors;
    },
    [UserGetters.IsEdited](state): boolean {
        return state.isEdited;
    },
    [UserGetters.LookupUsers](state): LookupEntity[] {
        return state.users.map((u) => ({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`
        }));
    }
};

export default getters;
