
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import DepartmentEditor from "@/components/Department/DepartmentEditor";
import { departmentModule } from "@/store/modules/department";
import { DepartmentGetters } from "@/store/modules/department/getters";
import { DepartmentActions } from "@/store/modules/department/actions";
import { leaveConfirmation } from "@/services/leaveConfirmation";

@Component({
    components: { DepartmentEditor },
    computed: {
        ...departmentModule.mapGetters({
            isLoading: DepartmentGetters.Loading,
            errors: DepartmentGetters.Errors
        })
    },
    methods: {
        ...departmentModule.mapActions({
            addNewDepartment: DepartmentActions.AddNewDepartment,
            createDepartment: DepartmentActions.CreateDepartment
        })
    }
})
export default class CreateDepartment extends Vue {
    private readonly addNewDepartment!: () => void;
    private readonly createDepartment!: () => Promise<void>;

    private leaveConfirmationEnabled = true;
    protected notificationMessage = "";
    protected notificationVisible = false;

    protected readonly isLoading!: boolean;
    protected readonly errors!: string[];
    private readonly isEdited!: boolean;

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    created(): void {
        this.addNewDepartment();
        window.onbeforeunload = () => this.isEdited;
    }

    create(): void {
        this.createDepartment().then(() =>
            this.$router.push(Routes.Departments)
        );
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
