import { Department } from "@/models/department/Department";
import { GetByPageRequest } from "@/services/requestUtils";
import api from ".";

export const caseDataSourceEndpoint = `${api.defaults.baseURL}cases/datasource`;

async function getDepartmentsByPage(payload: GetByPageRequest): Promise<{
    departments: Department[];
    totalCount: number;
}> {
    const response = await api.post("/departments/page", payload);
    return response.data;
}

async function getDepartments(): Promise<Department[]> {
    const response = await api.get("/departments");
    return response.data;
}

async function getDepartmentsByIds(ids: Array<number>): Promise<Department[]> {
    const response = await api.get(
        `/departments/byIds?${ids.map((n) => `ids=${n}`).join("&")}`
    );
    return response.data;
}

async function getDepartment(departmentId: number): Promise<Department> {
    const response = await api.get("/departments/" + departmentId);
    return response.data;
}

async function createDepartment(department: Department): Promise<number> {
    const response = await api.post("/departments", department);
    return response.data;
}

async function updateDepartment(department: Department): Promise<void> {
    const response = await api.post("/department/" + department.id, department);
    return response.data;
}

export default {
    getDepartmentsByPage,
    getDepartmentsByIds,
    getDepartments,
    getDepartment,
    createDepartment,
    updateDepartment
};
