
import Component from "vue-class-component";
import Vue from "vue";
import CredentialsForm from "@/components/Authentication/CredentialsForm";
import TwoFactorForm from "@/components/Authentication/TwoFactorForm";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountMutations } from "@/store/modules/account/mutations";
import { AccountGetters } from "@/store/modules/account/getters";
import { Routes } from "@/router/routes";
import Errors from "@/constants/BackendResponseErrors.json";
import { LoginResult } from "@/models/LoginResult";
import LoginFormData from "@/models/LoginFormData";
import ErrorNotification from "@/components/Shared/ErrorNotification";

@Component({
    components: { CredentialsForm, TwoFactorForm, ErrorNotification },
    methods: {
        ...accountModule.mapActions({
            logUserIn: AccountActions.LogUserIn,
            logUserInTwoFactor: AccountActions.LogUserInTwoFactor,
            getTwoFactorResetToken: AccountActions.GetTwoFactorResetToken
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...accountModule.mapGetters({
            getErrors: AccountGetters.Errors,
            getLoginResult: AccountGetters.LoginResult
        })
    }
})
export default class Login extends Vue {
    // Data
    protected twoFactorLogin = false;
    protected loginData: LoginFormData = new LoginFormData();

    // Mapped methods
    private readonly logUserIn!: (payload: {
        username: string;
        password: string;
    }) => Promise<void>;
    private readonly logUserInTwoFactor!: (payload: {
        twoFactorPassword: string;
    }) => Promise<void>;
    private readonly getTwoFactorResetToken!: (payload: {
        userName: string;
        recoveryCode: string;
    }) => Promise<string>;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: () => string[] | null;
    private readonly getLoginResult!: () => LoginResult | null;

    protected notificationMessage = "";
    protected notificationVisible = false;

    protected async submit(loginData: LoginFormData): Promise<void> {
        this.loginData = loginData;
        if (!this.twoFactorLogin) {
            await this.loginAsync();
            // A standard OTP has a length of 6. The form checks if it's 6 or 8 characters.
        } else if (this.loginData.twoFactorPassword.length == 6) {
            await this.twoFactorLoginAsync();
            // If it is not 6 characters long, it has to be 8 and is an emergency code.
        } else {
            await this.twoFactorResetAsync();
        }
    }

    private async loginAsync(): Promise<void> {
        await this.logUserIn({
            username: this.loginData.email,
            password: this.loginData.password
        });

        if (this.getLoginResult()?.isMultiFactorRequired) {
            this.twoFactorLogin = true;
            return;
        }

        this.showErrorDialogOrPush(
            (this.$route.query.redirecturl as string) ?? Routes.Cases
        );
    }

    private async twoFactorLoginAsync(): Promise<void> {
        await this.logUserInTwoFactor({
            twoFactorPassword: this.loginData.twoFactorPassword
        });

        this.showErrorDialogOrPush(
            (this.$route.query.redirecturl as string) ?? Routes.Cases
        );
    }

    private async twoFactorResetAsync(): Promise<void> {
        const token = await this.getTwoFactorResetToken({
            userName: this.loginData.email,
            recoveryCode: this.loginData.twoFactorPassword
        });

        this.showErrorDialogOrPush(
            Routes.TwoFactorActivation + "?token=" + token
        );
    }

    private showErrorDialogOrPush(pushTarget: string): void {
        const sessionErrors = this.getErrors();
        this.setErrors(null);
        if (sessionErrors !== undefined && sessionErrors !== null) {
            this.showNotification(sessionErrors[1] ?? Errors.Default.Message);
        } else if (pushTarget !== Routes.Login) {
            this.$router.push(pushTarget);
        } else {
            this.$router.push(Routes.Cases);
        }
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }
}
