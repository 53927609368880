import { UserSubstitute } from "@/models/User";
import api from ".";

async function listUserSubstitutes(userId: number): Promise<UserSubstitute[]> {
    const response = await api.get(`user/${userId}/substitute`);
    return response.data;
}

async function createUserSubstitute(
    userId: number,
    userSubstitute: Pick<UserSubstitute, "substituteId" | "dateFrom" | "dateTo">
): Promise<UserSubstitute> {
    const response = await api.post(
        `user/${userId}/substitute`,
        userSubstitute
    );
    return response.data;
}

async function updateUserSubstitute(
    userId: number,
    userSubstituteId: number,
    userSubstitute: Pick<UserSubstitute, "substituteId" | "dateFrom" | "dateTo">
): Promise<UserSubstitute> {
    const response = await api.put(
        `user/${userId}/substitute/${userSubstituteId}`,
        userSubstitute
    );
    return response.data;
}

async function deleteUserSubstitute(
    userId: number,
    userSubstituteId: number
): Promise<void> {
    await api.delete(`user/${userId}/substitute/${userSubstituteId}`);
}

export default {
    listUserSubstitutes,
    createUserSubstitute,
    updateUserSubstitute,
    deleteUserSubstitute
};
