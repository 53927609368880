
import Vue from "vue";
import Component from "vue-class-component";
import CaseList from "@/components/Case/CaseList";
import { Watch } from "vue-property-decorator";
import { stringEnumToArray } from "@/services/enumUtils";
import { CaseStatus, FullTextSearchType } from "@/models/case/Case";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { UserActions } from "@/store/modules/user/actions";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { Role, User } from "@/models/User";
import { getCurrentUser, getUserFullName } from "@/services/userUtils";
import { DataOptions, DataTableHeader } from "vuetify/types";
import { GetByPageRequest } from "@/services/requestUtils";
import VerticalExpansionPanelWrapper from "@/components/Shared/VerticalExpansionPanelWrapper";
import { CaseGetters } from "@/store/modules/case/getters";
import { DepartmentActions } from "@/store/modules/department/actions";
import { departmentModule } from "@/store/modules/department";
import { Tag, TagType } from "@/models/department/Tag";
import TagChip from "@/components/Tag/TagChip";
import { Department } from "@/models/department/Department";
import { DepartmentGetters } from "@/store/modules/department/getters";
import { isUserInRole } from "@/services/userUtils";
import { CaseMutations } from "@/store/modules/case/mutations";
import ErrorNotification from "@/components/Shared/ErrorNotification";
import CaseFilter from "@/models/case/CaseFilter";
import CaseFilterPanel from "@/models/case/CaseFilterPanel";
import localStorageService from "@/services/localStorageService";

const FILTER_LOCAL_STORAGE_KEY = "case-filter";
const FILTER_PANEL_LOCAL_STORAGE_KEY = "case-filter-panel";
const TABLE_OPTIONS_LOCAL_STORAGE_KEY = "case-table-options";

const filterKeys: (keyof CaseFilter)[] = [
    "isMyCases",
    "isMyDepartmentsValue",
    "isExpired",
    "isOnlyNotSolved",
    "searchText",
    "searchType",
    "isSearchableAttachments",
    "isDateMenuFromVisible",
    "dateFrom",
    "isDateMenuToVisible",
    "dateTo",
    "filterStatuses",
    "urgency",
    "salesforceId",
    "departmentId",
    "topicIds",
    "areaIds"
];

const filterPanelKeys: (keyof CaseFilterPanel)[] = [
    "fullTextFilterOpenedPanelIndex",
    "standartFilterOpenedPanelIndex"
];

@Component({
    components: {
        CaseList,
        TagChip,
        VerticalExpansionPanelWrapper,
        ErrorNotification
    },
    computed: {
        ...userModule.mapGetters({
            users: UserGetters.Users,
            user: UserGetters.User
        }),
        ...departmentModule.mapGetters({
            enabledDepartments: DepartmentGetters.EnabledDepartments
        }),
        ...caseModule.mapGetters({
            totalCases: CaseGetters.TotalCases,
            getErrors: CaseGetters.Errors
        })
    },
    methods: {
        ...caseModule.mapActions({
            loadCases: CaseActions.LoadCases,
            export: CaseActions.Export
        }),
        ...caseModule.mapMutations({
            setErrors: CaseMutations.SetErrors
        }),
        ...userModule.mapActions({
            loadUsers: UserActions.LoadUsers,
            loadUser: UserActions.LoadUserDetailsById
        }),
        ...departmentModule.mapActions({
            loadDepartments: DepartmentActions.LoadDepartments
        }),
        getUserFullName,
        getCurrentUser,
        isUserInRole
    }
})
export default class ViewCases
    extends Vue
    implements CaseFilter, CaseFilterPanel
{
    private readonly loadCases!: (payload: GetByPageRequest) => Promise<void>;
    private readonly export!: (payload: GetByPageRequest) => Promise<void>;
    private readonly loadUsers!: () => Promise<void>;
    private readonly loadUser!: (userId: number) => Promise<void>;
    private readonly loadDepartments!: () => Promise<void>;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: string[] | null;
    protected users!: User[];
    protected user!: User | null;
    protected enabledDepartments!: Department[];

    protected readonly statuses = stringEnumToArray(CaseStatus);
    protected dataTableOptions = {} as DataOptions;

    public isMyCases = false;
    public isMyDepartmentsValue = this.isUser;
    public isExpired = isUserInRole(Role.SU);
    public isOnlyNotSolved = true;

    public searchText = "";
    public searchType = FullTextSearchType.And;
    public searchTypeItems = [
        { text: "und", value: FullTextSearchType.And },
        { text: "oder", value: FullTextSearchType.Or },
        { text: "gesamter Ausdruck", value: FullTextSearchType.Phrase }
    ];
    public isDateMenuToVisible = false;
    public dateTo = null;
    public isDateMenuFromVisible = false;
    public dateFrom = null;
    public isSearchableAttachments = false;
    public filterStatuses = [] as string[];
    public urgency = {
        highUrgency: true,
        mediumUrgency: true,
        lowUrgency: true
    };
    public salesforceId = "";
    public departmentId = null;
    public topicIds = [] as number[];
    public areaIds = [] as number[];

    public fullTextFilterOpenedPanelIndex = 0;
    public standartFilterOpenedPanelIndex = 0;

    protected notificationMessage = "";
    protected notificationVisible = false;
    protected isPanelExpanded = true;

    protected isPageLoaded = false;

    get isMyDepartments(): boolean {
        return this.isUser ? true : this.isMyDepartmentsValue;
    }
    set isMyDepartments(value: boolean) {
        this.isMyDepartmentsValue = value;
    }

    get isUser(): boolean {
        return isUserInRole(Role.User);
    }

    get headers(): Array<DataTableHeader> {
        return [
            {
                text: "Dringlichkeit",
                align: "center",
                value: "urgency",
                width: "115px"
            },
            { text: "Status", value: "status", width: "120px" },
            {
                text: "Titel",
                value: "title",
                class: "titleColumn"
            },
            { text: "Erstellt", value: "creationDate", width: "120px" },
            {
                text: "Verantwortlicher",
                value: "responsible",
                width: "140px",
                sortable: false,
                class: "responsibleColumn"
            },
            {
                text: "Aktionen",
                value: "actions",
                sortable: false,
                width: "80px"
            }
        ];
    }

    get topics(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Topic)) ?? [];
    }

    get areas(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Area)) ?? [];
    }

    getFilterQueries(user: User): Array<{ name: string; value: unknown }> {
        const filterQueries = [];
        if (this.isMyCases)
            filterQueries.push({
                name: "userId",
                value: getCurrentUser()?.id
            });

        if (this.isMyDepartments)
            filterQueries.push({
                name: "departmentIds",
                value: user.departments
                    .filter((d) => d.isEnabled)
                    .map((el) => el.id)
            });

        filterQueries.push({
            name: "isExpired",
            value: this.isExpired
        });
        filterQueries.push({
            name: "isOnlyNotSolved",
            value: this.isOnlyNotSolved
        });

        if (this.searchText.length > 0) {
            filterQueries.push({
                name: "fullTextSearch",
                value: {
                    text: this.searchText,
                    searchType: this.searchType,
                    isSearchableAttachments: this.isSearchableAttachments
                }
            });
        }

        if (this.dateFrom) {
            filterQueries.push({
                name: "dateFrom",
                value: this.dateFrom
            });
        }

        if (this.dateTo) {
            filterQueries.push({
                name: "dateTo",
                value: this.dateTo
            });
        }

        filterQueries.push({ name: "status", value: this.filterStatuses });

        filterQueries.push({ name: "urgency", value: this.urgency });

        if (this.salesforceId)
            filterQueries.push({
                name: "salesforceId",
                value: this.salesforceId
            });

        if (this.departmentId)
            filterQueries.push({
                name: "department",
                value: this.departmentId
            });

        filterQueries.push({ name: "topic", value: this.topicIds });
        filterQueries.push({ name: "area", value: this.areaIds });

        return filterQueries;
    }

    created(): void {
        this.loadFilterPanel();
        this.loadFilter();
        this.loadCaseTableOptions();
    }

    async mounted(): Promise<void> {
        await this.loadUsers();
        await this.loadDepartments();

        const currentUserId = getCurrentUser()?.id;
        if (currentUserId) await this.loadUser(currentUserId);
        this.isPageLoaded = true;

        if (this.getErrors?.length) {
            this.notificationMessage = this.getErrors.join(". ");
            this.notificationVisible = true;

            this.setErrors(null);
        }
    }

    @Watch("departmentId")
    resetTopicAndAreaFilters(): void {
        this.topicIds = [];
        this.areaIds = [];
    }

    @Watch("dataTableOptions", { deep: true })
    async dataTableOptionsWatch(): Promise<void> {
        await this.triggerLoadCases();
        this.saveCaseTableOptions();
    }

    resetFilters(): void {
        this.searchText = "";
        this.searchType = FullTextSearchType.And;
        this.dateTo = null;
        this.dateFrom = null;
        this.isSearchableAttachments = false;
        this.filterStatuses = [];
        this.urgency = {
            highUrgency: true,
            mediumUrgency: true,
            lowUrgency: true
        };
        this.salesforceId = "";
        this.departmentId = null;
        this.topicIds = [];
        this.areaIds = [];
    }

    getTagsByType(type: TagType): Tag[][] {
        return this.enabledDepartments
            .filter((el) => (el.id ? this.departmentId == el.id : false))
            .map((dep) => dep.tags.filter((tag) => tag.type == type));
    }

    tagMatrixToArray(matrix: Tag[][]): Tag[] {
        let tags = [] as Tag[];
        for (let i = 0; i < matrix.length; i++) {
            tags = tags.concat(matrix[i]);
        }
        return tags;
    }

    async exportCases(): Promise<void> {
        await this.applyFilters();
        if (this.user)
            await this.export({
                options: this.dataTableOptions,
                filterQueries: this.getFilterQueries(this.user)
            });
    }

    async triggerLoadCases(): Promise<void> {
        if (this.user)
            await this.loadCases({
                options: this.dataTableOptions,
                filterQueries: this.getFilterQueries(this.user)
            });
    }

    @Watch("isMyCases")
    @Watch("isMyDepartments")
    @Watch("isExpired")
    @Watch("isOnlyNotSolved")
    async applyFilters(): Promise<void> {
        if (this.dataTableOptions) this.dataTableOptions.page = 1;
        await this.triggerLoadCases();
        this.saveFilter();
    }

    saveFilter(): void {
        localStorageService.saveProperties<keyof CaseFilter>(
            FILTER_LOCAL_STORAGE_KEY,
            this as Record<string, unknown>,
            filterKeys
        );
    }

    loadFilter(): void {
        localStorageService.loadProperties<keyof CaseFilter>(
            FILTER_LOCAL_STORAGE_KEY,
            this as Record<string, unknown>,
            filterKeys
        );
    }

    saveCaseTableOptions(): void {
        localStorageService.saveObject(
            TABLE_OPTIONS_LOCAL_STORAGE_KEY,
            this.dataTableOptions
        );
    }

    loadCaseTableOptions(): void {
        const loadedDataTableOptions =
            localStorageService.loadObject<DataOptions>(
                TABLE_OPTIONS_LOCAL_STORAGE_KEY
            );
        if (loadedDataTableOptions !== null)
            this.dataTableOptions = loadedDataTableOptions;
    }

    @Watch("fullTextFilterOpenedPanelIndex")
    @Watch("standartFilterOpenedPanelIndex")
    saveFilterPanel(): void {
        localStorageService.saveProperties<keyof CaseFilterPanel>(
            FILTER_PANEL_LOCAL_STORAGE_KEY,
            this as Record<string, unknown>,
            filterPanelKeys
        );
    }

    loadFilterPanel(): void {
        localStorageService.loadProperties<keyof CaseFilterPanel>(
            FILTER_PANEL_LOCAL_STORAGE_KEY,
            this as Record<string, unknown>,
            filterPanelKeys
        );
    }
}
