
import Vue from "vue";
import Component from "vue-class-component";
import AppIcon from "../AppIcon";
import { Routes } from "@/router/routes";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountGetters } from "@/store/modules/account/getters";
import { Session } from "@/models/Session";
import { Role, UserSubstitute } from "@/models/User";
import { isUserInRole } from "@/services/userUtils";
import TermsOfUsePopup from "@/components/Shared/TermsOfUsePopup";
import UserSubstitutePopup from "@/components/Shared/UserSubstitutePopup";
import AdminSubstitutePopup from "@/components/Shared/AdminSubstitutePopup";
import TimespanLabel from "@/components/Shared/TimespanLabel";
import { substituteModule } from "@/store/modules/substitute";
import { SubstituteActions } from "@/store/modules/substitute/actions";

@Component({
    components: {
        AppIcon,
        TermsOfUsePopup,
        UserSubstitutePopup,
        AdminSubstitutePopup,
        TimespanLabel
    },
    computed: {
        ...accountModule.mapGetters({
            session: AccountGetters.Session,
            substitutes: AccountGetters.Substitutes
        })
    },
    methods: {
        ...accountModule.mapActions({
            logUserOut: AccountActions.LogUserOut
        }),
        ...substituteModule.mapActions({
            loadUsers: SubstituteActions.LoadUsers
        })
    }
})
export default class UserBadge extends Vue {
    private readonly session!: Session | null;
    protected readonly substitutes!: UserSubstitute[];
    protected readonly logUserOut!: () => Promise<void>;
    protected readonly loadUsers!: () => Promise<void>;

    protected menuVisible = false;
    protected termsVisible = false;
    protected userSubstituteVisible = false;
    protected adminSubstituteVisible = false;

    get fullname(): string {
        return this.session?.User.firstName + " " + this.session?.User.lastName;
    }

    get isUserAdmin(): boolean {
        return isUserInRole(Role.Admin);
    }

    async logout(): Promise<void> {
        await this.logUserOut();
        this.$router.push(Routes.Login);
    }

    showMenu(): void {
        this.menuVisible = !this.menuVisible;

        this.$el.addEventListener("click", (e) => e.stopPropagation());
        document.onclick = () => {
            this.menuVisible = false;
            document.onclick = null;
        };
    }

    async showUserSubstitutePopup(): Promise<void> {
        await this.loadUsers();
        this.userSubstituteVisible = true;
    }

    async showAdminSubstitutePopup(): Promise<void> {
        await this.loadUsers();
        this.adminSubstituteVisible = true;
    }
}
