import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { Routes } from "./routes";
import Login from "@/pages/Authentication/Login";
import PasswordReset from "@/pages/Authentication/PasswordReset";
import RequestPasswordReset from "@/pages/Authentication/RequestPasswordReset";
import AccountActivation from "@/pages/Authentication/AccountActivation";
import TwoFactorActivation from "../pages/TwoFactorActivation";
import Error from "@/pages/Error";
import Users from "@/pages/User/ViewUsers";
import CreateUser from "@/pages/User/CreateUser";
import EditUser from "@/pages/User/EditUser";
import Cases from "@/pages/Case/ViewCases";
import Case from "@/pages/Case/ViewCase";
import NonUserCase from "@/pages/Case/NonUserViewCase";
import CreateCase from "@/pages/Case/CreateCase";
import CreateDepartment from "@/pages/Department/CreateDepartment";
import EditDepartment from "@/pages/Department/EditDepartment";
import ViewDepartments from "@/pages/Department/ViewDepartments";
import { Role } from "@/models/User";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: Routes.Login,
        name: "login",
        component: Login,
        meta: {
            layout: "authLayout",
            title: "Login",
            allowAnonymous: true
        }
    },
    {
        path: Routes.RequestPasswordReset,
        name: "requestPasswordReset",
        component: RequestPasswordReset,
        meta: {
            layout: "authLayout",
            title: "Reset Password",
            allowAnonymous: true
        }
    },
    {
        path: Routes.PasswordReset,
        name: "passwordReset",
        component: PasswordReset,
        meta: {
            layout: "authLayout",
            title: "Reset Password",
            allowAnonymous: true
        }
    },
    {
        path: Routes.AccountActivation,
        name: "accountActivation",
        component: AccountActivation,
        meta: {
            layout: "authLayout",
            title: "Activate account",
            allowAnonymous: true
        }
    },
    {
        path: Routes.TwoFactorActivation,
        name: "twoFactorActivation",
        component: TwoFactorActivation,
        meta: {
            layout: "authLayout",
            title: "Activate two factor authentication",
            allowAnonymous: true
        }
    },
    {
        path: Routes.Users,
        name: "users",
        component: Users,
        meta: {
            layout: "panelLayout",
            title: "Users",
            allowedRoles: [Role.SU, Role.Admin]
        }
    },
    {
        path: Routes.EditUser + ":id",
        name: "editUser",
        component: EditUser,
        meta: {
            title: "Edit User",
            allowedRoles: [Role.SU, Role.Admin]
        }
    },
    {
        path: Routes.CreateUser,
        name: "createUser",
        component: CreateUser,
        meta: {
            title: "Create User",
            allowedRoles: [Role.SU, Role.Admin]
        }
    },
    {
        path: Routes.CreateCase,
        name: "createCase",
        component: CreateCase,
        meta: {
            title: "Create Case",
            allowedRoles: [Role.SU, Role.Admin, Role.User]
        }
    },
    {
        path: Routes.Cases,
        name: "cases",
        component: Cases,
        meta: {
            layout: "panelLayout",
            title: "Cases",
            allowedRoles: [Role.SU, Role.Admin, Role.User]
        }
    },
    {
        path: Routes.Case + ":id",
        name: "case",
        component: Case,
        meta: {
            layout: "panelLayout",
            title: "Case",
            allowedRoles: [Role.SU, Role.Admin, Role.User]
        }
    },
    {
        path: Routes.NonUserCase + ":id",
        name: "nonUserCase",
        component: NonUserCase,
        meta: {
            layout: "nonUserLayout",
            title: "Case",
            allowOnlyAnonymous: true
        }
    },
    {
        path: Routes.Departments,
        name: "departments",
        component: ViewDepartments,
        meta: {
            layout: "panelLayout",
            title: "View Departments",
            allowedRoles: [Role.SU, Role.Admin]
        }
    },
    {
        path: Routes.CreateDepartment,
        name: "createDepartment",
        component: CreateDepartment,
        meta: {
            title: "Create Department",
            allowedRoles: [Role.SU, Role.Admin]
        }
    },
    {
        path: Routes.EditDepartment + ":id",
        name: "editDepartment",
        component: EditDepartment,
        meta: {
            title: "Edit Department",
            allowedRoles: [Role.SU, Role.Admin]
        }
    },
    {
        path: Routes.Error + ":code",
        name: "error",
        component: Error,
        meta: {
            title: "Error"
        }
    },
    {
        path: "*",
        name: "404",
        redirect: () => Routes.Error + "404"
    }
];

const router = new VueRouter({
    routes: routes,
    mode: "history"
});

export default router;
