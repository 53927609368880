
import Vue from "vue";
import Component from "vue-class-component";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { emailRules } from "@/services/validationUtils";

@Component({
    data() {
        return { emailRules };
    },
    methods: {
        ...accountModule.mapActions({
            requestPasswordReset: AccountActions.RequestPasswordReset
        })
    }
})
export default class PasswordResetForm extends Vue {
    private readonly requestPasswordReset!: (payload: {
        email: string;
    }) => Promise<void>;

    protected valid = false;
    protected popupVisible = false;

    requestPasswordResetModel = {
        email: ""
    };

    get email(): string {
        return this.requestPasswordResetModel.email;
    }

    set email(emailAddress: string) {
        this.requestPasswordResetModel.email = emailAddress;
    }

    async submit(): Promise<void> {
        (
            this.$refs.requestPasswordResetForm as Vue & {
                validate: () => boolean;
            }
        ).validate();
        if (this.valid) {
            await this.requestPasswordReset({
                email: this.requestPasswordResetModel.email
            });

            this.popupVisible = true;
        }
    }
}
