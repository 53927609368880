
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { stringEnumToArray } from "@/services/enumUtils";
import { Case, CaseStatus, CaseType, CaseUrgency } from "@/models/case/Case";
import { User } from "@/models/User";
import { caseModule } from "@/store/modules/case";
import { CaseMutations } from "@/store/modules/case/mutations";
import { CaseGetters } from "@/store/modules/case/getters";
import { getUserFullName, checkIsUserDisabled } from "@/services/userUtils";
import { Department } from "@/models/department/Department";
import { DepartmentGetters } from "@/store/modules/department/getters";
import { departmentModule } from "@/store/modules/department";
import { Tag, TagType } from "@/models/department/Tag";
import TagChip from "@/components/Tag/TagChip";
import FileUploadErrorPopup from "@/components/Shared/FileUploadErrorPopup";
import UserAutocomplete from "@/components/Shared/UserAutocomplete";
import DepartmentAutocomplete from "@/components/Shared/DepartmentAutocomplete";
import {
    titleRules,
    typeRules,
    descriptionRules,
    departmentRules,
    salesforceIdRules,
    customerNameRules,
    receivingDateRules,
    statusRules,
    urgencyRules,
    responsibleRules
} from "@/services/validationUtils";

@Component({
    data() {
        return {
            titleRules,
            typeRules,
            descriptionRules,
            departmentRules,
            salesforceIdRules,
            customerNameRules,
            receivingDateRules,
            statusRules,
            urgencyRules,
            responsibleRules
        };
    },
    components: {
        TagChip,
        FileUploadErrorPopup,
        UserAutocomplete,
        DepartmentAutocomplete
    },
    computed: {
        ...caseModule.mapGetters({
            caseItem: CaseGetters.Case,
            newAttachments: CaseGetters.NewAttachmenst
        }),
        ...departmentModule.mapGetters({
            departments: DepartmentGetters.Departments,
            enabledDepartments: DepartmentGetters.EnabledDepartments
        })
    },
    methods: {
        ...caseModule.mapMutations({
            updateCase: CaseMutations.UpdateCase,
            addAttachments: CaseMutations.AddAttachments
        }),
        getUserFullName,
        checkIsUserDisabled
    }
})
export default class CaseEditor extends Vue {
    private readonly caseItem?: Case | null;
    private readonly newAttachments!: File[];

    private readonly updateCase!: (payload: Partial<Case>) => void;
    protected readonly statuses = stringEnumToArray(CaseStatus);
    protected readonly types = stringEnumToArray(CaseType);
    protected readonly urgencies = stringEnumToArray(CaseUrgency);
    private readonly addAttachments!: (files: File[]) => void;
    protected readonly enabledDepartments!: Department[];
    protected readonly departments!: Department[];
    protected valid = false;
    protected dateMenu = false;

    private allowedFileTypes = [
        "message/rfc822", // .eml file
        "application/vnd.ms-outlook", // .msg file
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" // .pptx
    ];
    protected junkFiles = [] as string[];
    protected showFileUploadErrorDialog = false;

    protected disableSaveButton = false;

    get title(): string {
        return this.caseItem?.title ?? "";
    }
    set title(value: string) {
        this.updateCase({ title: value });
    }

    get description(): string {
        return this.caseItem?.description ?? "";
    }
    set description(value: string) {
        this.updateCase({ description: value });
    }

    get status(): CaseStatus | null {
        if (this.caseItem?.type) return this.caseItem.status;
        return null;
    }
    set status(value: CaseStatus | null) {
        if (value) {
            this.updateCase({ status: value });
        }
    }

    get type(): CaseType | null {
        if (this.caseItem?.type) return this.caseItem.type;
        return null;
    }
    set type(value: CaseType | null) {
        if (value) {
            this.updateCase({ type: value });
        }
    }

    get topics(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Topic)) ?? [];
    }

    get topic(): Tag[] {
        return (
            this.caseItem?.tags?.filter((el) => el.type == TagType.Topic) ?? []
        );
    }

    set topic(value: Tag[]) {
        const areas = this.caseItem?.tags?.filter(
            (el) => el.type == TagType.Area
        );
        const newTags = [...(areas ?? []), ...value];
        this.updateCase({ tags: newTags });
    }

    get areas(): Tag[] {
        return this.tagMatrixToArray(this.getTagsByType(TagType.Area)) ?? [];
    }

    get area(): Tag[] {
        return (
            this.caseItem?.tags?.filter((el) => el.type == TagType.Area) ?? []
        );
    }

    set area(value: Tag[]) {
        const topics = this.caseItem?.tags?.filter(
            (el) => el.type == TagType.Topic
        );
        const newTags = [...(topics ?? []), ...value];
        this.updateCase({ tags: newTags });
    }

    get salesforceId(): string {
        return this.caseItem?.salesforceId ?? "";
    }
    set salesforceId(value: string) {
        this.updateCase({ salesforceId: value });
    }

    get urgency(): CaseUrgency | null {
        if (this.caseItem?.urgency) return this.caseItem.urgency;
        return null;
    }
    set urgency(value: CaseUrgency | null) {
        if (value) {
            this.updateCase({ urgency: value });
        }
    }

    get customerName(): string {
        return this.caseItem?.customerName ?? "";
    }
    set customerName(value: string) {
        this.updateCase({ customerName: value });
    }

    get users(): User[] {
        if (this.department)
            return (
                this.departments
                    .find((el) => el.id == this.department)
                    ?.members.filter((user) => user.isEnabled) ?? []
            );
        return [];
    }

    get responsible(): number | null {
        if (this.caseItem?.responsibleId) return this.caseItem.responsibleId;
        return null;
    }
    set responsible(value: number | null) {
        if (value) {
            this.updateCase({ responsibleId: value });
        }
    }

    get department(): number | null {
        if (this.caseItem?.departmentId) return this.caseItem.departmentId;
        return null;
    }

    set department(value: number | null) {
        if (value) {
            this.updateCase({ departmentId: value });
        }
    }

    get receivingDate(): Date | null {
        if (this.caseItem?.receivingDate) return this.caseItem.receivingDate;
        return null;
    }

    set receivingDate(value: Date | null) {
        if (value) {
            this.updateCase({ receivingDate: value });
        }
    }

    set attachments(files: File[]) {
        if (files?.length > 0) {
            this.junkFiles = [];
            for (let i = 0; i < files.length; i++) {
                if (files[i].name.endsWith(".msg")) {
                    const file = new File([files[i]], files[i].name, {
                        type: "application/vnd.ms-outlook"
                    });
                    files[i] = file;
                }
                if (
                    files[i].size > 10000000 ||
                    !this.allowedFileTypes.includes(files[i].type)
                ) {
                    this.showFileUploadErrorDialog = true;
                    this.junkFiles.push(files[i].name);
                    files.splice(i, 1);
                }
            }
            this.addAttachments(files);
        }
    }

    get attachments(): File[] {
        return this.newAttachments;
    }

    @Watch("department")
    resetTopicAreaResponsible(): void {
        this.topic = [];
        this.area = [];
        this.responsible = null;
    }

    getTagsByType(type: TagType): Tag[][] {
        return this.departments
            .filter((el) => (el.id ? this.department == el.id : false))
            .map((dep) => dep.tags.filter((tag) => tag.type == type));
    }

    tagMatrixToArray(matrix: Tag[][]): Tag[] {
        let tags = [] as Tag[];
        for (let i = 0; i < matrix.length; i++) {
            tags = tags.concat(matrix[i]);
        }
        return tags;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    addDropFile(e: any): void {
        this.attachments = Array.from(e.dataTransfer.files);
    }

    submit(): void {
        (
            this.$refs.caseEditorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.disableSaveButton = true;
            this.$emit("submit", () => (this.disableSaveButton = false));
        }
    }
}
