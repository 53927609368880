
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class TimespanLabel extends Vue {
    @Prop({ type: String, required: true })
    public dateFrom!: string;

    @Prop({ type: String, required: true })
    public dateTo!: string;

    @Prop({ type: String, default: "indigo" })
    public color!: string;

    @Prop({ type: String, default: "mdi-account-supervisor" })
    public icon!: string;

    get labelText(): string {
        return `${new Date(this.dateFrom).toLocaleDateString()} - ${new Date(
            this.dateTo
        ).toLocaleDateString()}`;
    }

    click(): void {
        this.$emit("click");
    }
}
