
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Comment } from "@/models/case/Comment";

@Component({})
export default class CommentEditorCore extends Vue {
    // @PropSync("showDialog", { type: Boolean, required: true })
    // private showDialogSync!: boolean;

    @Prop({ type: Object, required: true })
    private comment!: Comment | null;

    @Prop({ type: Function, required: true })
    private updateCurrentComment!: (payload: Partial<Comment>) => void;

    protected valid = false;

    protected textRules = [
        (v: string): boolean | string => {
            return !!v || "Comment text is required";
        },
        (v: string): boolean | string => {
            return (
                v.length <= 8000 ||
                "Comment text must be at most 8000 characters long"
            );
        }
    ];

    get text(): string {
        return this.comment?.text ?? "";
    }
    set text(value: string) {
        this.updateCurrentComment({ text: value });
    }

    save(): void {
        (
            this.$refs.commentEditorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.$emit("save");
        }
    }

    cancel(): void {
        this.$emit("cancel");
    }
}
