
import Vue from "vue";
import Component from "vue-class-component";
import { accountModule } from "@/store/modules/account";
import { AccountActions } from "@/store/modules/account/actions";
import { AccountMutations } from "@/store/modules/account/mutations";
import { AccountGetters } from "@/store/modules/account/getters";
import Errors from "@/constants/BackendResponseErrors.json";
import GenericPasswordChangeForm from "@/components/Authentication/GenericPasswordChangeForm";
import ErrorNotification from "@/components/Shared/ErrorNotification";

@Component({
    components: {
        GenericPasswordChangeForm,
        ErrorNotification
    },
    methods: {
        ...accountModule.mapActions({
            resetPassword: AccountActions.ResetPassword
        }),
        ...accountModule.mapMutations({
            setErrors: AccountMutations.SetErrors
        }),
        ...accountModule.mapGetters({
            getErrors: AccountGetters.Errors
        })
    }
})
export default class PasswordResetForm extends Vue {
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly getErrors!: () => string[] | null;
    private readonly resetPassword!: (payload: {
        token: string;
        newPassword: string;
    }) => Promise<void>;

    protected token = "";
    protected popupVisible = false;

    protected notificationMessage = "";
    protected notificationVisible = false;

    mounted(): void {
        const queryToken = this.$route.query.token as string;
        if (queryToken == null || queryToken.length == 0) {
            this.showNotification(Errors.Default.Message);
        } else {
            this.token = queryToken;
        }
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }

    async submit(password: string): Promise<void> {
        await this.resetPassword({
            token: this.$route.query.token as string,
            newPassword: password
        });

        const sessionErrors = this.getErrors();
        this.setErrors(null);

        if (sessionErrors !== undefined && sessionErrors !== null) {
            if (sessionErrors[0].includes(Errors.InvalidToken.Error)) {
                this.showNotification(Errors.InvalidToken.Message);
            } else if (sessionErrors[0].includes(Errors.NetworkError.Error)) {
                this.showNotification(Errors.NetworkError.Message);
            } else if (sessionErrors[0].includes(Errors.BadRequest.Error)) {
                this.showNotification(
                    "Das Passwort muss Buchstaben, Zahlen und Sonderzeichen enthalten."
                );
            } else {
                this.showNotification(Errors.Default.Message);
            }
        } else {
            this.popupVisible = true;
        }
    }
}
