
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { emailRules, passwordRules } from "@/services/validationUtils";

@Component({
    data() {
        return { emailRules, passwordRules };
    }
})
export default class GenericPasswordChangeForm extends Vue {
    @Prop({ type: String, required: true })
    public readonly buttonTitle!: string;

    protected valid = false;
    protected showPassword = false;
    protected showConfirmPassword = false;

    protected confirmPasswordRules = [
        (v: string): boolean | string => {
            return !!v || "Password is required";
        },
        this.passwordConfirmationRule
    ];

    private passwordConfirmModel = {
        password: "",
        passwordConfirm: ""
    };

    get password(): string {
        return this.passwordConfirmModel.password;
    }

    set password(password: string) {
        (
            this.$refs.passwordConfirmForm as Vue & { validate: () => boolean }
        ).validate();

        this.passwordConfirmModel.password = password;
    }

    get passwordConfirm(): string {
        return this.passwordConfirmModel.passwordConfirm;
    }

    set passwordConfirm(passwordConfirm: string) {
        (
            this.$refs.passwordConfirmForm as Vue & { validate: () => boolean }
        ).validate();
        this.passwordConfirmModel.passwordConfirm = passwordConfirm;
    }

    passwordConfirmationRule(): boolean | string {
        return (
            this.passwordConfirmModel.password ===
                this.passwordConfirmModel.passwordConfirm ||
            "Password must match"
        );
    }

    async submit(): Promise<void> {
        (
            this.$refs.passwordConfirmForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.$emit("update:password", this.passwordConfirmModel.password);
        }
    }
}
