import { User, UserSubstitute } from "@/models/User";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export interface SubstituteState {
    userId: number | null;
    users: User[];
    userSubstitutes: UserSubstitute[];
    editingSubstitute: Partial<UserSubstitute>;
    errors: string[] | null;
    loading: boolean;
}

const substituteState: SubstituteState = {
    userId: null,
    users: [],
    userSubstitutes: [],
    editingSubstitute: {},
    errors: null,
    loading: false
};

const substitute: Module<SubstituteState, RootState> = {
    namespaced: true,
    state: substituteState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const substituteModule = createNamespacedHelpers("substitute");

export default substitute;
