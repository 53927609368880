import departmentApi from "@/api/departmentApi";
import { GetByPageRequest, makeRequest } from "@/services/requestUtils";
import { RootState } from "@/store";
import { ActionTree, Commit } from "vuex";
import { DepartmentState } from ".";
import { DepartmentMutations } from "./mutations";
import { UserActions } from "@/store/modules/user/actions";
import { Department } from "@/models/department/Department";

export enum DepartmentActions {
    LoadDepartments = "loadDepartments",
    LoadDepartmentsByPage = "loadDepartmentsByPage",
    LoadDepartmentsByIds = "loadDepartmentsByIds",
    LoadDepartment = "loadDepartment",
    AddNewDepartment = "addNewDepartment",
    CreateDepartment = "createDepartment",
    UpdateDepartment = "updateDepartment",
    CreateTag = "createTag"
}

async function makeDepartmentRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        DepartmentMutations.SetErrors,
        DepartmentMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<DepartmentState, RootState> = {
    async [DepartmentActions.LoadDepartments](context): Promise<void> {
        await makeDepartmentRequest(
            context.commit,
            () => departmentApi.getDepartments(),
            (result) => {
                context.commit(DepartmentMutations.SetDepartments, result);
            }
        );
    },
    async [DepartmentActions.LoadDepartmentsByPage](
        context,
        payload: GetByPageRequest
    ): Promise<void> {
        await makeRequest(
            context.commit,
            DepartmentMutations.SetErrors,
            DepartmentMutations.SetLoading,
            () => departmentApi.getDepartmentsByPage(payload),
            (result: { departments: Department[]; totalCount: number }) => {
                context.commit(
                    DepartmentMutations.SetDepartments,
                    result.departments
                );
                context.commit(
                    DepartmentMutations.SetTotalDepartments,
                    result.totalCount
                );
            }
        );
    },
    async [DepartmentActions.LoadDepartment](
        context,
        payload: number
    ): Promise<void> {
        await makeDepartmentRequest(
            context.commit,
            () => departmentApi.getDepartment(payload),
            (result) => {
                context.commit(DepartmentMutations.SetDepartment, result);
            }
        );
    },
    async [DepartmentActions.LoadDepartmentsByIds](
        context,
        payload: Array<number>
    ) {
        await makeDepartmentRequest(
            context.commit,
            () => departmentApi.getDepartmentsByIds(payload),
            (result) => {
                context.commit(DepartmentMutations.SetDepartments, result);
            }
        );
    },
    [DepartmentActions.AddNewDepartment](context): void {
        context.dispatch("user/" + UserActions.LoadUsers, null, {
            root: true
        });

        const department: Partial<Department> = {
            name: ""
        };
        context.commit(DepartmentMutations.SetDepartment, department);
    },
    async [DepartmentActions.CreateDepartment](context): Promise<void> {
        const department = context.state.department;
        if (department) {
            await makeDepartmentRequest(context.commit, () =>
                departmentApi.createDepartment(department)
            );
        }
    },
    async [DepartmentActions.UpdateDepartment](context): Promise<void> {
        const department = context.state.department;
        if (department) {
            await makeDepartmentRequest(
                context.commit,
                () => departmentApi.updateDepartment(department),
                () =>
                    context.commit(
                        DepartmentMutations.UpdateDepartment,
                        department
                    )
            );
        }
    }
};

export default actions;
