import nonUserApi from "@/api/nonUserApi";
import { RootState } from "@/store";
import { NonUserState } from ".";
import { ActionTree, Commit } from "vuex";
import { AccessEmail } from "@/models/nonUser/AccessEmail";
import { NonUserMutations } from "./mutations";
import { makeRequest } from "@/services/requestUtils";
import { saveData } from "@/services/saveData";
import { CaseMutations } from "../case/mutations";
import { Comment } from "@/models/case/Comment";

export enum NonUserActions {
    SendAccess = "sendAccess",
    AddAccessEmail = "addAccessEmail",
    LoadCase = "loadCase",
    DownloadAttachment = "downloadAttachment",
    AddNewCurrentComment = "addNewCurrentComment",
    CreateComment = "createComment"
}

async function makeNonUserRequest<T>(
    commit: Commit,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    await makeRequest(
        commit,
        NonUserMutations.SetErrors,
        NonUserMutations.SetLoading,
        callback,
        onSuccess
    );
}

const actions: ActionTree<NonUserState, RootState> = {
    async [NonUserActions.SendAccess](context): Promise<void> {
        const accessEmail = context.state.accessEmail;
        if (accessEmail) {
            await makeNonUserRequest(
                context.commit,
                () => nonUserApi.sendAccess(accessEmail),
                (historyItem) => {
                    historyItem.creationDate = new Date(
                        historyItem.creationDate
                    );
                    context.commit(
                        "caseItem/" + CaseMutations.AddHistoryItem,
                        historyItem,
                        { root: true }
                    );
                }
            );
        }
    },
    [NonUserActions.AddAccessEmail](context, payload: AccessEmail): void {
        context.commit(NonUserMutations.SetAccessEmail, payload);
    },
    async [NonUserActions.LoadCase](
        context,
        payload: { token: string; caseId: number }
    ): Promise<void> {
        await makeNonUserRequest(
            context.commit,
            () => nonUserApi.getCase(payload.token, payload.caseId),
            (result) => {
                context.commit(NonUserMutations.SetCase, result);
            }
        );
    },
    [NonUserActions.AddNewCurrentComment](context): void {
        const comment: Partial<Comment> = {
            text: "",
            caseId: context.state.case?.id
        };
        context.commit(NonUserMutations.SetCurrentComment, comment);
    },
    async [NonUserActions.CreateComment](
        context,
        token: string
    ): Promise<void> {
        const comment = context.state.currentComment;
        if (comment) {
            await makeNonUserRequest(
                context.commit,
                () => nonUserApi.createComment(token, comment),
                (result) => context.commit(CaseMutations.AddComment, result)
            );
        }
    },
    async [NonUserActions.DownloadAttachment](
        context,
        payload: {
            token: string;
            attachmentId: number;
            caseId: number;
        }
    ): Promise<void> {
        await makeNonUserRequest(
            context.commit,
            () =>
                nonUserApi.downloadAttachment(
                    payload.token,
                    payload.attachmentId,
                    payload.caseId
                ),
            (file) => {
                saveData(file.data, file.filename);
            }
        );
    }
};

export default actions;
