
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import VerticalExpansionPanelWrapper from "@/components/Shared/VerticalExpansionPanelWrapper";
import { DataOptions } from "vuetify";
import { GetByPageRequest } from "@/services/requestUtils";
import DepartmentList from "@/components/Department/DepartmentList";
import { departmentModule } from "@/store/modules/department";
import { DepartmentActions } from "@/store/modules/department/actions";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { UserActions } from "@/store/modules/user/actions";
import { Role, User } from "@/models/User";
import {
    getUserFullName,
    isUserInRole,
    checkIsUserDisabled
} from "@/services/userUtils";

@Component({
    components: {
        VerticalExpansionPanelWrapper,
        DepartmentList
    },
    computed: {
        ...userModule.mapGetters({
            users: UserGetters.Users
        })
    },
    methods: {
        ...departmentModule.mapActions({
            loadDepartmentsByPage: DepartmentActions.LoadDepartmentsByPage
        }),
        ...userModule.mapActions({
            loadUsers: UserActions.LoadUsers
        }),
        getUserFullName,
        checkIsUserDisabled
    }
})
export default class ViewDepartments extends Vue {
    private readonly loadDepartmentsByPage!: (
        payload: GetByPageRequest
    ) => Promise<void>;

    private readonly loadUsers!: () => Promise<void>;
    protected readonly users!: User[];

    protected dataTableOptions: DataOptions | null = null;
    protected page = 1;

    protected nameFilterValue = "";
    protected managersFilterValue = [];

    protected readonly headers = [
        { text: "Name", value: "name" },
        { text: "Mitglieder", value: "membersCount", sortable: false },
        { text: "Manager", value: "managers", sortable: false },
        {
            text: "Aktiv",
            value: "active",
            width: "80px",
            align: "center"
        },
        {
            text: "Aktionen",
            value: "actions",
            sortable: false,
            width: "80px",
            align: "center"
        }
    ];

    protected collapsed = true;
    protected divWidth = "width: 500px";

    created(): void {
        this.loadUsers();
    }

    resetFilters(): void {
        this.nameFilterValue = "";
        this.managersFilterValue = [];
    }

    get isUserAdmin(): boolean {
        return isUserInRole(Role.Admin);
    }

    get getFilterQueries(): Array<{ name: string; value: unknown }> {
        const filterQueries = [];
        filterQueries.push({
            name: "name",
            value: this.nameFilterValue
        });
        filterQueries.push({
            name: "managers",
            value: this.managersFilterValue
        });
        return filterQueries;
    }

    addNewDepartment(): void {
        this.$router.push(Routes.CreateDepartment);
    }

    async updateData(options: DataOptions): Promise<void> {
        this.dataTableOptions = options;
        await this.loadDepartmentsByPage({
            options: this.dataTableOptions,
            filterQueries: this.getFilterQueries
        });
    }

    async applyFilters(): Promise<void> {
        await this.loadDepartmentsByPage({
            options: this.dataTableOptions,
            filterQueries: this.getFilterQueries
        });
        this.page = 1;
    }

    async reload(): Promise<void> {
        await this.loadDepartmentsByPage({
            options: this.dataTableOptions,
            filterQueries: this.getFilterQueries
        });
    }
}
