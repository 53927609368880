
import Vue from "vue";
import Component from "vue-class-component";
import { userModule } from "@/store/modules/user";
import { roleModule } from "@/store/modules/role";
import { UserGetters } from "@/store/modules/user/getters";
import { User, Role } from "@/models/User";
import { Routes } from "@/router/routes";
import { UserActions } from "@/store/modules/user/actions";
import { RoleGetters } from "@/store/modules/role/getters";
import { alert } from "devextreme/ui/dialog";
import { Prop, PropSync, Watch } from "vue-property-decorator";
import {
    getCurrentUser,
    getUserFullName,
    getUserInitials,
    isUserInRole
} from "@/services/userUtils";
import { footerPageText } from "@/services/dataTableUtils";
import { DataTableHeader } from "vuetify";

@Component({
    computed: {
        ...userModule.mapGetters({
            users: UserGetters.Users,
            totalUsers: UserGetters.TotalUsers,
            loading: UserGetters.Loading
        }),
        ...roleModule.mapGetters({
            roles: RoleGetters.Roles
        })
    },
    methods: {
        ...userModule.mapActions({
            getTwoFactorRecoveryCode: UserActions.GetTwoFactorRecoveryCode
        }),
        getCurrentUser,
        getUserFullName,
        getUserInitials,
        footerPageText
    }
})
export default class UserList extends Vue {
    @Prop({ type: Array, required: true })
    public readonly headers!: Array<DataTableHeader>;

    @PropSync("page", { type: Number })
    public syncPage!: number;

    private readonly getTwoFactorRecoveryCode!: (
        userId: number
    ) => Promise<string>;
    protected readonly totalUsers!: number;

    protected readonly users!: User[];
    protected readonly roles!: string[];
    protected loading!: boolean;

    protected options = {};

    @Watch("options", { deep: true })
    updateOptions(): void {
        this.$emit("updateOptions", this.options);
    }

    editUser(user: User): void {
        if (user?.id) {
            this.$router.push(Routes.EditUser + user.id);
        }
    }

    isCurrentUserProfile(id: number): boolean {
        return getCurrentUser()?.id == id;
    }

    isCurrentUserCanManageUserWithRole(role: string): boolean {
        return role === Role.User || isUserInRole(Role.Admin);
    }

    async generateCode(user: User): Promise<void> {
        if (user?.id) {
            await alert(
                `Two factor emergency code for ${
                    user.email
                }: ${await this.getTwoFactorRecoveryCode(user.id)}`,
                "Emergency Code"
            );
        }
    }

    has2FADisabled(user: User): boolean {
        return !user.twoFactorEnabled ?? true;
    }
}
