
import localStorageService from "@/services/localStorageService";
import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";

const LOCAL_STORAGE_KEY = "is-vertical-expansion-panel-visible";

@Component
export default class VerticalExpansionPanelWrapper extends Vue {
    private isPanedExpandedInternalValue = true;

    @Model("change", { type: Boolean, default: undefined })
    protected value?: boolean;

    @Prop({ type: String, required: true })
    protected panelHeader!: string;

    get isPanelExpanded(): boolean {
        return this.value !== undefined
            ? this.value
            : this.isPanedExpandedInternalValue;
    }

    get expansionPanelWidth(): string {
        const widthPx = this.isPanelExpanded ? 450 : 65;
        return `width: ${widthPx}px`;
    }

    created(): void {
        this.loadIsPanelExpanded();
    }

    setIsPanelExpanded(value: boolean): void {
        if (this.value !== undefined) this.$emit("change", value);
        else this.isPanedExpandedInternalValue = value;
    }

    saveIsPanelExpanded(): void {
        localStorageService.saveBoolean(
            LOCAL_STORAGE_KEY,
            this.isPanelExpanded
        );
    }

    loadIsPanelExpanded(): void {
        const loadedValue = localStorageService.loadBoolean(LOCAL_STORAGE_KEY);
        if (loadedValue !== null) this.setIsPanelExpanded(loadedValue);
    }

    onChevronClick(): void {
        this.setIsPanelExpanded(!this.isPanelExpanded);
        this.$nextTick(this.saveIsPanelExpanded);
    }
}
