
import Vue from "vue";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { Component, Prop } from "vue-property-decorator";
import { User } from "@/models/User";
import { HistoryItem } from "@/models/case/HistoryItem";
import { getUserFullName } from "@/services/userUtils";
import { Department } from "@/models/department/Department";
import { departmentModule } from "@/store/modules/department";
import { DepartmentGetters } from "@/store/modules/department/getters";

@Component({
    computed: {
        ...userModule.mapGetters({
            users: UserGetters.Users
        }),
        ...departmentModule.mapGetters({
            departments: DepartmentGetters.Departments
        })
    },
    methods: {
        getUserFullName
    }
})
export default class HistoryItemLabel extends Vue {
    @Prop({ type: Object })
    private readonly historyItem!: HistoryItem;
    protected readonly users!: User[];
    protected readonly departments!: Department[];

    labelText(): string {
        let text = " hat das Feld ";
        switch (this.historyItem?.propertyName) {
            case "ResponsibleId":
                text += `Verantwortlich von "${getUserFullName(
                    this.getUserById(this.historyItem.oldPropertyValue)
                )}" auf "${getUserFullName(
                    this.getUserById(this.historyItem.newPropertyValue)
                )}" geändert`;
                break;
            case "DepartmentId":
                text += `Team von "${
                    this.getDepartmentById(this.historyItem.oldPropertyValue)
                        ?.name
                }" auf "${
                    this.getDepartmentById(this.historyItem.newPropertyValue)
                        ?.name
                }" geändert`;
                break;
            case "ReceivingDate":
                text += `Eingangsdatum von
                    ${this.historyItem.oldPropertyValue?.substring(0, 10)}
                    auf
                    ${this.historyItem.newPropertyValue?.substring(
                        0,
                        10
                    )} geändert`;
                break;
            case "Attachments":
                text = `hat die Datei ${this.historyItem.newPropertyValue} `;
                text +=
                    this.historyItem.oldPropertyValue == "Adding"
                        ? "hinzugefügt"
                        : "entfernt";
                break;
            case "ActiveReminder":
                text = `hat den Reminder `;
                text +=
                    this.historyItem.oldPropertyValue == "True"
                        ? "ausgeschaltet"
                        : "eingeschaltet";
                break;
            case "SalesforceId":
                text += `Salesforce ID `;
                if (
                    this.historyItem.oldPropertyValue &&
                    this.historyItem.oldPropertyValue.length > 0
                )
                    text += `von "${this.historyItem.oldPropertyValue}"`;
                text += ` auf "${this.historyItem.newPropertyValue}" geändert`;
                break;
            case "Description":
                text += "Bezeichnung geändert";
                break;
            case "NonUserAccess":
                text = ` hat "${this.historyItem.newPropertyValue}"`;
                if (this.historyItem.oldPropertyValue)
                    text += ` Zugriff auf den Case mit dem Kommentar "${this.historyItem.oldPropertyValue}" gewährt`;
                break;
            case "Tags":
                text += `Themen & Bereiche von "${this.historyItem.oldPropertyValue}" auf "${this.historyItem.newPropertyValue}" geändert`;
                break;
            default:
                text += `${this.getNamingByPropertyName(
                    this.historyItem.propertyName
                )} von "${this.historyItem.oldPropertyValue}" auf "${
                    this.historyItem.newPropertyValue
                }" geändert`;
        }
        return text;
    }

    getUserById(id: string | undefined): User | undefined {
        return this.users.find((el) => el.id == Number(id));
    }

    getDepartmentById(id: string | undefined): Department | undefined {
        return this.departments.find((el) => el.id == Number(id));
    }

    getFullDate(date: Date | null): string {
        if (date) return new Date(date).toLocaleString();
        return "No data";
    }

    getNamingByPropertyName(name: string): string {
        switch (name) {
            case "Urgency":
                return "Dringlichkeit";
            case "Type":
                return "Typ";
            case "CustomerName":
                return "Kundenname";
        }

        return name;
    }
}
