import { HistoryItem } from "@/models/case/HistoryItem";
import { AxiosResponse } from "axios";
import { DataOptions } from "vuetify";
import { Commit } from "vuex";
import { getErrorTextsFromErrorResponse } from "./responseErrorUtils";

export interface GetByPageRequest {
    options?: DataOptions | null;
    filterQueries?: Array<{ name: string; value: unknown }> | null;
}

export interface CaseCreateResponse {
    isResponsibleSubstituted: boolean;
    caseId: number;
}

export interface CaseUpdateResponse {
    isResponsibleSubstituted: boolean;
    historyItems: HistoryItem[] | null;
}

export async function makeRequest<T>(
    commit: Commit,
    errorMutations: string,
    loadingMutation: string,
    callback: () => Promise<T>,
    onSuccess?: (result: T) => void
): Promise<void> {
    commit(errorMutations, null);
    try {
        commit(loadingMutation, true);
        const result = await callback();
        if (onSuccess) {
            onSuccess(result);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        const response: AxiosResponse = error.response;

        if (response) {
            commit(errorMutations, getErrorTextsFromErrorResponse(error));
        } else {
            commit(errorMutations, ["Network error!"]);
        }
    } finally {
        commit(loadingMutation, false);
    }
}

export async function saveAttachment(
    id: number,
    attachments: File[],
    removeAttachmentsId: number[],
    addAttachment: (id: number, value: File) => Promise<AxiosResponse>,
    removeAttachment: (id: number, value: number) => Promise<AxiosResponse>
): Promise<void> {
    await attachmentRequest(id, attachments, addAttachment);
    await attachmentRequest(id, removeAttachmentsId, removeAttachment);
}

async function attachmentRequest<T>(
    id: number,
    attachments: T[],
    request: (caseId: number, value: T) => Promise<AxiosResponse>
) {
    if (attachments.length) {
        const requests: Promise<AxiosResponse>[] = [];
        attachments.forEach((a) => {
            requests.push(request(id, a));
        });
        await Promise.all(requests);
    }
}
