
import { Tag } from "@/models/department/Tag";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TagEditor extends Vue {
    @Prop({ type: Object, required: true })
    public readonly tag!: Tag;

    protected valid = false;

    protected nameRules = [
        (v: string): boolean | string => {
            return !!v || "Name ist ein Pflichtfeld";
        },
        (v: string): boolean | string => {
            return v.length <= 50 || "Name darf höchstens 50 Zeichen lang sein";
        }
    ];

    get color(): string {
        return this.tag?.color ?? "";
    }
    set color(value: string) {
        this.tag.color = value;
    }

    resetValidation(): void {
        (
            this.$refs.tagEditorForm as Vue & { resetValidation: () => void }
        ).resetValidation();
    }

    submit(): void {
        (
            this.$refs.tagEditorForm as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.$emit("submit", this.tag);
        }
    }
}
