import { Session } from "@/models/Session";
import { MutationTree } from "vuex";
import { AccountState } from ".";
import { LoginResult } from "@/models/LoginResult";
import { UserSubstitute } from "@/models/User";

export enum AccountMutations {
    SetSession = "setSession",
    SetLoginResult = "setLoginResult",
    SetErrors = "setErrors",
    SetSessionUserSubstitutes = "setSessionUserSubstitutes"
}

const mutations: MutationTree<AccountState> = {
    [AccountMutations.SetSession](state, payload: Session): void {
        state.session = payload;
    },
    [AccountMutations.SetLoginResult](
        state,
        payload: LoginResult | null
    ): void {
        state.loginResult = payload;
    },
    [AccountMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [AccountMutations.SetSessionUserSubstitutes](
        state,
        payload: UserSubstitute[]
    ): void {
        if (state.session) state.session.User.userSubstitutes = payload;
    }
};

export default mutations;
