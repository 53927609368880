import { User, UserSubstitute } from "@/models/User";
import { MutationTree } from "vuex";
import { SubstituteState } from ".";

export enum SubstituteMutations {
    SetUserId = "setUserId",
    SetUsers = "addUsers",
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    SetUserSubstitutes = "setUserSubstitutes",
    SetEditingSubstitute = "setEditingSubstitute",
    UpdateEditingSubstitute = "updateSubstitute",
    DeleteUserSubstitute = "deleteUserSubstitute"
}

const mutations: MutationTree<SubstituteState> = {
    [SubstituteMutations.SetUserId](state, userId: number | null) {
        state.userId = userId;
    },
    [SubstituteMutations.SetUsers](state, payload: User[]): void {
        state.users = payload;
    },

    [SubstituteMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [SubstituteMutations.SetLoading](state, payload: boolean): void {
        state.loading = payload;
    },
    [SubstituteMutations.SetUserSubstitutes](
        state,
        payload: UserSubstitute[]
    ): void {
        state.userSubstitutes = payload;
    },
    [SubstituteMutations.SetEditingSubstitute](
        state,
        payload: Partial<UserSubstitute>
    ): void {
        state.editingSubstitute = payload;
    },
    [SubstituteMutations.UpdateEditingSubstitute](
        state,
        payload: Partial<UserSubstitute>
    ): void {
        if (state.editingSubstitute) {
            state.editingSubstitute = {
                ...state.editingSubstitute,
                ...payload
            };
        }
    },
    [SubstituteMutations.DeleteUserSubstitute](state, userSubstituteId) {
        state.userSubstitutes = state.userSubstitutes.filter(
            (s) => s.userSubstituteId !== userSubstituteId
        );
    }
};

export default mutations;
