import { Department } from "@/models/department/Department";

export enum TagType {
    Area = "Area",
    Topic = "Topic"
}

export interface Tag {
    id?: number;
    name: string;
    type: TagType;
    color: string;
    department: Department;
}
