
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class TextBoxViewWidget extends Vue {
    @Prop({ type: String, required: true })
    private value!: string;

    @Prop({ type: String, default: "" })
    private placeholder!: string;

    @Prop({ type: Array, default: [] })
    private rules!: (() => boolean | string)[];

    @Prop({ type: String, default: "100%" })
    private width!: string;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    protected initialValue!: string;
    protected localValue!: string;
    protected valid = false;
    private isDisplayMode = true;

    created(): void {
        this.initialValue = this.value;
        this.localValue = this.value;
    }

    changeMode(): void {
        if (!this.disabled) {
            this.isDisplayMode = !this.isDisplayMode;
            this.localValue = this.initialValue;
        }
    }

    save(): void {
        (
            this.$refs.textBoxWidget as Vue & { validate: () => boolean }
        ).validate();
        if (this.valid) {
            this.$emit("update:value", this.localValue);
            this.initialValue = this.localValue;
            this.changeMode();
        }
    }
}
