
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class EditCase extends Vue {
    @Prop({ type: String, required: true })
    private model!: string;

    @Prop({ type: Array, default: [] })
    private items!: string[];

    @Prop({ type: String, default: "" })
    private placeholder!: string;

    @Prop({ type: String, default: "100%" })
    private width!: string;

    private isDisplayMode = true;

    get localModel(): string {
        return this.model;
    }

    set localModel(value: string) {
        this.$emit("update:model", value);
    }

    changeMode(): void {
        this.isDisplayMode = !this.isDisplayMode;
    }
}
