
import Vue from "vue";
import Component from "vue-class-component";
import { caseModule } from "@/store/modules/case";
import { CaseActions } from "@/store/modules/case/actions";
import { CaseGetters } from "@/store/modules/case/getters";
import { Routes } from "@/router/routes";
import CaseEditor from "@/components/Case/CaseEditor";
import InfoPopup from "@/components/Shared/InfoPopup";
import ErrorNotification from "@/components/Shared/ErrorNotification";
import { CaseMutations } from "@/store/modules/case/mutations";
import { departmentModule } from "@/store/modules/department";
import { DepartmentActions } from "@/store/modules/department/actions";
import { CaseCreateResponse } from "@/services/requestUtils";

@Component({
    components: { CaseEditor, InfoPopup, ErrorNotification },
    computed: {
        ...caseModule.mapGetters({
            isLoading: CaseGetters.Loading,
            getErrors: CaseGetters.Errors
        })
    },
    methods: {
        ...caseModule.mapActions({
            addNewCase: CaseActions.AddNewCase,
            createCase: CaseActions.CreateCase,
            saveAttachments: CaseActions.SaveAttachments
        }),
        ...caseModule.mapMutations({
            setErrors: CaseMutations.SetErrors
        }),
        ...departmentModule.mapActions({
            loadDepartments: DepartmentActions.LoadDepartments
        })
    }
})
export default class CreateCase extends Vue {
    private readonly addNewCase!: () => void;
    private readonly createCase!: () => Promise<CaseCreateResponse | undefined>;
    private readonly saveAttachments!: (caseId: number) => Promise<void>;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly loadDepartments!: () => Promise<void>;

    protected readonly isLoading!: boolean;
    private readonly getErrors!: string[] | null;

    protected notificationMessage = "";
    protected notificationVisible = false;
    protected substitutionNotificationVisible = false;

    async created(): Promise<void> {
        this.addNewCase();
        await this.loadDepartments();
    }

    async create(enableSaveButton: () => void): Promise<void> {
        const caseCreateResponse = await this.createCase();

        const caseErrors = this.getErrors;
        if (caseErrors || !caseCreateResponse) {
            this.showNotification(
                caseErrors?.join(". ") ?? "Cannot create the case."
            );
            this.setErrors(null);
            enableSaveButton();
            return;
        }

        await this.saveAttachments(caseCreateResponse.caseId);

        if (caseCreateResponse.isResponsibleSubstituted) {
            const attachmentErrors = this.getErrors;
            if (attachmentErrors?.length) {
                this.showNotification(attachmentErrors.join(". "));
                this.setErrors(null);
            }
            this.substitutionNotificationVisible = true;
        } else {
            this.redirectToCases();
        }
        enableSaveButton();
    }

    redirectToCases(): void {
        this.$router.push(Routes.Cases);
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }
}
