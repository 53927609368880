import { DataOptions } from "vuetify/types";

export function footerPageText(
    options: DataOptions,
    totalCount: number
): string {
    const fromRecord = (options.page - 1) * options.itemsPerPage + 1;
    const toRecord =
        options.itemsPerPage > -1
            ? (options.page - 1) * options.itemsPerPage + options.itemsPerPage >
              totalCount
                ? totalCount
                : (options.page - 1) * options.itemsPerPage +
                  options.itemsPerPage
            : totalCount;

    return `${fromRecord}-${toRecord} von ${totalCount}`;
}
