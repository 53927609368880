
import Vue from "vue";
import Component from "vue-class-component";
import { Routes } from "@/router/routes";
import { userModule } from "@/store/modules/user";
import { UserActions } from "@/store/modules/user/actions";
import UserEditor from "@/components/User/UserEditor";
import { UserGetters } from "@/store/modules/user/getters";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import { User } from "@/models/User";
import { UserMutations } from "@/store/modules/user/mutations";
import { RoleActions } from "@/store/modules/role/actions";
import { roleModule } from "@/store/modules/role";
import Errors from "@/constants/BackendResponseErrors.json";

@Component({
    components: { UserEditor },
    computed: {
        ...userModule.mapGetters({
            isEdited: UserGetters.IsEdited,
            user: UserGetters.User,
            isLoading: UserGetters.Loading
        })
    },
    methods: {
        ...userModule.mapActions({
            loadUserDetailsById: UserActions.LoadUserDetailsById,
            commitUserUpdate: UserActions.CommitUserUpdate
        }),
        ...userModule.mapMutations({
            setUser: UserMutations.SetUser,
            setErrors: UserMutations.SetErrors
        }),
        ...roleModule.mapActions({
            loadRoles: RoleActions.LoadRoles
        }),
        ...userModule.mapGetters({
            getErrors: UserGetters.Errors
        })
    }
})
export default class EditUser extends Vue {
    private readonly loadUserDetailsById!: (userId: number) => Promise<void>;
    private readonly commitUserUpdate!: () => Promise<void>;
    private readonly setUser!: (user: Partial<User> | null) => void;
    private readonly loadRoles!: () => Promise<void>;
    private readonly getErrors!: () => string[] | null;
    private readonly setErrors!: (payload: string[] | null) => void;

    protected readonly isLoading!: boolean;
    protected readonly user!: Partial<User> | null;
    private readonly isEdited!: boolean;
    private leaveConfirmationEnabled = true;
    protected readonly roles!: string[];
    protected notificationMessage = "";
    protected notificationVisible = false;

    created(): void {
        if (!this.roles) {
            this.loadRoles();
        }

        if (this.userId) {
            this.setUser(null);
            this.loadUserDetailsById(this.userId);
        }
        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    saveUser(): void {
        this.commitUserUpdate().then(() => {
            const errors = this.getErrors();
            this.setErrors(null);
            if (errors !== undefined && errors !== null) {
                const validationErrors = errors.filter((e) =>
                    e.includes(Errors.ValidationProblem.Error)
                );
                if (
                    errors.find((e) =>
                        e.includes(Errors.DuplicateEmail.Error)
                    ) !== undefined
                ) {
                    this.showNotification(Errors.DuplicateEmail.Message);
                } else if (validationErrors !== undefined) {
                    this.showNotification(
                        validationErrors
                            .map((error) => {
                                return error.substr(
                                    error.search("message:") + 8
                                );
                            })
                            .join(" ")
                    );
                } else {
                    this.showNotification(Errors.Default.Message);
                }
            } else {
                this.leaveConfirmationEnabled = false;
                this.$router.push(Routes.Users);
            }
        });
    }

    showNotification(message: string): void {
        this.notificationMessage = message;
        this.notificationVisible = true;
    }

    get userId(): number | null {
        const userId = Number(this.$route.params.id);
        return !isNaN(userId) ? userId : null;
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
