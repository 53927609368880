import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { AccessEmail } from "@/models/nonUser/AccessEmail";
import { Case } from "@/models/case/Case";
import { Comment } from "@/models/case/Comment";

export interface NonUserState {
    accessEmail: AccessEmail | null;
    errors: string[] | null;
    loading: boolean;
    case: Case | null;
    currentComment: Comment | null;
}

const nonUserState: NonUserState = {
    accessEmail: null,
    errors: null,
    loading: false,
    case: null,
    currentComment: null
};

const nonUser: Module<NonUserState, RootState> = {
    namespaced: true,
    state: nonUserState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const nonUserModule = createNamespacedHelpers("nonUser");

export default nonUser;
