import { Department } from "@/models/department/Department";
import { Tag } from "@/models/department/Tag";
import { MutationTree } from "vuex";
import { DepartmentState } from ".";

export enum DepartmentMutations {
    SetDepartments = "setDepartments",
    SetTotalDepartments = "setTotalDepartments",
    SetDepartment = "setDepartment",
    UpdateDepartment = "updateDepartment",
    RemoveDepartment = "removeDepartment",
    SetErrors = "setErrors",
    SetLoading = "setLoading",
    AddTagToDepartment = "AddTagToDepartment"
}

const mutations: MutationTree<DepartmentState> = {
    [DepartmentMutations.SetDepartments](state, payload: Department[]): void {
        state.departments = payload;
    },
    [DepartmentMutations.SetTotalDepartments](state, payload: number): void {
        state.totalDepartments = payload;
    },
    [DepartmentMutations.SetDepartment](state, payload: Department) {
        state.department = payload;
        state.isEdited = false;
    },
    [DepartmentMutations.UpdateDepartment](
        state,
        payload: Partial<Department>
    ): void {
        if (state.department) {
            state.department = { ...state.department, ...payload };
            state.isEdited = true;
        }
    },
    [DepartmentMutations.RemoveDepartment](state, payload: number) {
        state.departments = state.departments.filter((u) => u.id !== payload);
    },
    [DepartmentMutations.SetErrors](state, payload: string[] | null): void {
        state.errors = payload;
    },
    [DepartmentMutations.SetLoading](state, payload: boolean): void {
        state.loading = payload;
    },
    [DepartmentMutations.AddTagToDepartment](state, payload: Tag): void {
        if (state.department) state.department.tags.push(payload);
    }
};

export default mutations;
