type errorsType = {
    [K: string]: {
        title: string;
        message: string;
    };
};

const errors: errorsType = {
    "404": {
        title: "404! Nicht gefunden",
        message: "Seite wurde nicht gefunden!"
    },
    "403": {
        title: "403! Zugriff verweigert",
        message: "Sie haben keinen Zugriff auf diesen Inhalt!"
    },
    "500": {
        title: "500! Interner Server-Fehler",
        message: "Interner Server-Fehler."
    },
    "*": {
        title: "Unknown error!",
        message: ""
    }
};

export default errors;
