import { Session } from "@/models/Session";
import localStorageService from "@/services/localStorageService";
import { RootState } from "@/store";
import { createNamespacedHelpers, Module } from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { LoginResult } from "@/models/LoginResult";

export interface AccountState {
    session: Session | null;
    loginResult: LoginResult | null;
    errors: string[] | null;
}

export const SESSION_KEY = "session";

const accountState: AccountState = {
    session: localStorageService.loadObject(SESSION_KEY),
    loginResult: null,
    errors: null
};

const account: Module<AccountState, RootState> = {
    namespaced: true,
    state: accountState,
    getters: getters,
    mutations: mutations,
    actions: actions
};

export const accountModule = createNamespacedHelpers("account");

export default account;
