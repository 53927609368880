import userApi from "@/api/userApi";
import { User } from "@/models/User";
import { RootState } from "@/store";
import { ActionContext, ActionTree, Commit } from "vuex";
import { UserState } from ".";
import { UserMutations } from "./mutations";
import { getErrorTextsFromErrorResponse } from "@/services/responseErrorUtils";
import { GetByPageRequest, makeRequest } from "@/services/requestUtils";

export enum UserActions {
    LoadUsers = "loadUsers",
    LoadUsersByPage = "loadUsersByPage",
    LoadUsersByIds = "loadUsersByIds",
    LoadUserDetailsById = "loadUserDetailsById",
    AddNewUser = "addNewUser",
    CommitUserUpdate = "commitUserUpdate",
    CreateUser = "createUser",
    GetTwoFactorRecoveryCode = "getTwoFactorRecoveryCode"
}

async function loadUsers(
    commit: Commit,
    load: () => Promise<User[]>,
    saveCommit = UserMutations.SetUsers
): Promise<void> {
    commit(UserMutations.SetLoading, true);
    try {
        const users = await load();
        commit(saveCommit, users);
    } catch {
        commit(UserMutations.SetErrors, ["Error"]);
    } finally {
        commit(UserMutations.SetLoading, false);
    }
}

const actions: ActionTree<UserState, RootState> = {
    async [UserActions.LoadUsers](context): Promise<void> {
        await makeRequest(
            context.commit,
            UserMutations.SetErrors,
            UserMutations.SetLoading,
            () => userApi.getUsers(),
            (result) => {
                context.commit(UserMutations.SetUsers, result);
            }
        );
    },
    async [UserActions.LoadUsersByPage](
        context,
        payload: GetByPageRequest
    ): Promise<void> {
        await makeRequest(
            context.commit,
            UserMutations.SetErrors,
            UserMutations.SetLoading,
            () => userApi.getUsersByPage(payload),
            (result: { users: User[]; totalCount: number }) => {
                context.commit(UserMutations.SetUsers, result.users);
                context.commit(UserMutations.SetTotalUsers, result.totalCount);
            }
        );
    },
    async [UserActions.LoadUsersByIds]({ commit }, payload: Array<number>) {
        await loadUsers(commit, () => userApi.getUsersByIds(payload));
    },
    async [UserActions.LoadUserDetailsById]({ commit }, payload: number) {
        commit(UserMutations.SetLoading, true);
        try {
            const user = await userApi.getUserDetails(payload);
            commit(UserMutations.SetUser, user);
        } catch {
            commit(UserMutations.SetErrors, ["Error"]);
        } finally {
            commit(UserMutations.SetLoading, false);
        }
    },
    [UserActions.AddNewUser]({ commit }) {
        const user: Partial<User> = {
            firstName: "",
            lastName: "",
            email: ""
        };
        commit(UserMutations.SetUser, user);
    },
    async [UserActions.CommitUserUpdate](context): Promise<void> {
        try {
            if (context.state.user) {
                await userApi.updateUser(context.state.user);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [UserActions.CreateUser](context): Promise<void> {
        try {
            if (context.state.user) {
                await userApi.createUser(context.state.user);
            }
        } catch (e) {
            handleReponseError(e, context);
        }
    },
    async [UserActions.GetTwoFactorRecoveryCode](context, payload: number) {
        try {
            return await userApi.getTwoFactorRecoveryCode(payload);
        } catch (e) {
            handleReponseError(e, context);
        }
    }
};

export function handleReponseError(
    error: Error,
    context: ActionContext<UserState, RootState>
): void {
    context.commit(
        UserMutations.SetErrors,
        getErrorTextsFromErrorResponse(error)
    );
}

export default actions;
