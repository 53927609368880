
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";

@Component({})
export default class InfoPopup extends Vue {
    @PropSync("visible", { type: Boolean, required: true })
    public popupVisible!: boolean;

    @Prop({ type: Boolean, default: false })
    public persistent!: boolean;

    @Prop({ type: String, required: true })
    public title!: string;

    @Prop({ type: String, required: true })
    public buttonText!: string;

    @Prop({ type: Number, default: 1200 })
    public width!: number;

    submit(): void {
        this.popupVisible = false;
        this.$emit("submit");
    }
}
