
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Comment } from "@/models/case/Comment";
import { caseModule } from "@/store/modules/case";
import { CaseMutations } from "@/store/modules/case/mutations";
import { CaseGetters } from "@/store/modules/case/getters";
import CommentEditorCore from "@/components/Case/CommentEditorCore";

@Component({
    components: { CommentEditorCore },
    computed: {
        ...caseModule.mapGetters({
            comment: CaseGetters.CurrentComment
        })
    },
    methods: {
        ...caseModule.mapMutations({
            updateCurrentComment: CaseMutations.UpdateCurrentComment
        })
    }
})
export default class CommentEditor extends Vue {
    private readonly comment?: Comment | null;
    private readonly updateCurrentComment!: (payload: Partial<Comment>) => void;

    save(): void {
        this.$emit("save");
    }

    cancel(): void {
        this.$emit("cancel");
    }
}
