import { User } from "@/models/User";
import store from "@/store";
import { AccountGetters } from "@/store/modules/account/getters";

export function getUserTitle(user: User): string {
    const baseTitle = `${user.role} | ${user.firstName} ${user.lastName}`;
    return baseTitle;
}

const roleClaim =
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

const manageUserClaim = "BMW.Welt.WorkFlowManagementTool.UserManage";

export function isUserInRole(role: string, user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        return (
            user.role === role ||
            (user.claims?.[roleClaim]?.includes(role) ?? false)
        );
    }
    return false;
}

export function getUserFullName(user?: User): string {
    if (user) {
        return user.isEnabled
            ? `${user.firstName} ${user.lastName}`
            : `User ${user.id}`;
    }
    return "";
}

export function getUserInitials(user?: User): string {
    if (user) {
        return user.firstName.substring(0, 1) + user.lastName.substring(0, 1);
    }
    return "";
}

export function isUserInRoles(roles: string[], user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user) {
        for (const role of roles) {
            if (isUserInRole(role, user)) {
                return true;
            }
        }
    }
    return false;
}

export function canUserManageRole(role: string, user?: User): boolean {
    user = user ?? getCurrentUser();
    if (user && user.claims) {
        return (
            user.claims?.[manageUserClaim]?.find((r) => r == role) !== undefined
        );
    }

    return false;
}

export function isUserAuthorized(): boolean {
    return getCurrentUser() !== null;
}

export function getCurrentUser(): User | undefined {
    return store.getters["account/" + AccountGetters.Session]?.User ?? null;
}

export function checkIsUserDisabled(user: User): boolean {
    return !user.isEnabled;
}
