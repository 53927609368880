
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { userModule } from "@/store/modules/user";
import { UserActions } from "@/store/modules/user/actions";
import CommentViewWidget from "@/components/Case/CommentViewWidget";
import NonUserCommentEditor from "@/components/Case/NonUserCommentEditor";
import HistoryItemLabel from "@/components/Case/HistoryItemLabel";
import { getUserFullName, getUserInitials } from "@/services/userUtils";
import { Case } from "@/models/case/Case";
import { Event, EventType } from "@/models/case/Event";
import { nonUserModule } from "@/store/modules/nonUser";
import { NonUserActions } from "@/store/modules/nonUser/actions";
import { Routes } from "@/router/routes";
import { NonUserGetters } from "@/store/modules/nonUser/getters";
import { departmentModule } from "@/store/modules/department";
import { DepartmentActions } from "@/store/modules/department/actions";

@Component({
    components: {
        CommentViewWidget,
        NonUserCommentEditor,
        HistoryItemLabel
    },
    computed: {
        ...nonUserModule.mapGetters({
            caseItem: NonUserGetters.Case
        })
    },
    methods: {
        ...nonUserModule.mapActions({
            loadCase: NonUserActions.LoadCase,
            downloadAttachment: NonUserActions.DownloadAttachment,
            addNewCurrentComment: NonUserActions.AddNewCurrentComment,
            createComment: NonUserActions.CreateComment
        }),
        ...userModule.mapActions({
            loadUsersByIds: UserActions.LoadUsersByIds
        }),
        ...departmentModule.mapActions({
            loadDepartmentsByIds: DepartmentActions.LoadDepartmentsByIds
        }),
        getUserFullName,
        getUserInitials
    }
})
export default class NonUserViewCase extends Vue {
    private caseItem!: Case;

    private readonly addNewCurrentComment!: () => Promise<void>;
    private readonly createComment!: (token: string) => Promise<void>;
    private readonly loadUsersByIds!: (ids: Array<number>) => Promise<void>;
    private readonly loadDepartmentsByIds!: (
        ids: Array<number>
    ) => Promise<void>;
    private readonly loadCase!: ({
        token,
        caseId
    }: {
        token: string;
        caseId: number;
    }) => Promise<void>;
    private readonly downloadAttachment!: ({
        token,
        attachmentId,
        caseId
    }: {
        token: string;
        attachmentId: number;
        caseId: number;
    }) => void;

    protected showCommentEditor = false;

    get caseId(): number | null {
        const id = this.$router.currentRoute.params.id;
        const parsedId = Number(id);
        return isNaN(parsedId) ? null : parsedId;
    }

    get token(): string | null {
        const token = this.$router.currentRoute.query.token;
        return typeof token === "string" ? token : null;
    }

    get title(): string {
        return this.caseItem?.title ?? "";
    }

    get description(): string {
        return this.caseItem?.description ?? "";
    }

    get timelineItems(): Event[] {
        return [...this.caseItem.comments, ...this.caseItem.historyItems].sort(
            (a, b) => {
                return (
                    Number(new Date(b.creationDate)) -
                    Number(new Date(a.creationDate))
                );
            }
        );
    }

    async created(): Promise<void> {
        if (this.caseId !== null && this.token !== null) {
            await this.loadCase({ token: this.token, caseId: this.caseId });
            if (!this.caseItem) this.$router.push(Routes.Login);
            await this.loadHistoryItemUsers();
            await this.loadHistoryItemDepartments();
        } else {
            this.$router.push(Routes.Login);
        }
    }

    getTimelineItemIconColor(item: Event): string {
        if (!item.author) return "grey darken-1";

        if (item.author.email.endsWith("@gkk.de")) return "orange darken-1";

        if (item.author.email.endsWith("@bmw.de")) return "indigo darken-1";

        return "teal darken-1";
    }

    isEventComment(event: Event): boolean {
        return event.type == EventType.Comment;
    }

    getCaseAge(caseItem?: Case): string {
        if (caseItem?.creationDate) {
            const dateNow = new Date();
            const creationDate = new Date(caseItem.creationDate);

            let minutes = Math.floor(
                Math.floor(
                    (dateNow.getTime() - creationDate.getTime()) / 1000
                ) / 60
            );
            let hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            hours = hours - days * 24;
            minutes = minutes - days * 24 * 60 - hours * 60;
            return `${days > 0 ? days + (days > 1 ? " Tage" : " Tag") : ""} ${
                hours > 0 ? hours + (hours > 1 ? " Stunden" : " Stunde") : ""
            } ${minutes + (minutes > 1 ? " Minuten" : " Minute")}`;
        }
        return "";
    }

    async loadHistoryItemUsers(): Promise<void> {
        const responsibleHistoryItems = this.caseItem.historyItems.filter(
            (el) => el.propertyName == "ResponsibleId"
        );
        let ids = responsibleHistoryItems.map((el) =>
            Number(el.newPropertyValue)
        );
        ids = ids.concat(
            responsibleHistoryItems.map((el) => Number(el.oldPropertyValue))
        );
        await this.loadUsersByIds([...new Set(ids)]);
    }

    async loadHistoryItemDepartments(): Promise<void> {
        const departmentHistoryItems = this.caseItem.historyItems.filter(
            (el) => el.propertyName == "DepartmentId"
        );
        let ids = departmentHistoryItems.map((el) =>
            Number(el.newPropertyValue)
        );
        ids = ids.concat(
            departmentHistoryItems.map((el) => Number(el.oldPropertyValue))
        );
        await this.loadDepartmentsByIds([...new Set(ids)]);
    }

    addComment(): void {
        this.addNewCurrentComment();
        this.showCommentEditor = true;
    }

    async createNewComment(): Promise<void> {
        if (this.token) await this.createComment(this.token);
        this.showCommentEditor = false;
    }

    async downloadCaseAttachment(attachmentId: number): Promise<void> {
        if (this.caseId && this.token)
            await this.downloadAttachment({
                token: this.token,
                attachmentId,
                caseId: this.caseId
            });
    }
}
