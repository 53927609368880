import "vue-class-component/hooks";
import router from "@/router";
import Component from "vue-class-component";
import { Route, RawLocation } from "vue-router";
import { isUserAuthorized, isUserInRoles } from "@/services/userUtils";
import { Routes } from "@/router/routes";

declare module "vue/types/vue" {
    interface Vue {
        beforeRouteEnter?(
            to: Route,
            from: Route,
            next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
        ): void;

        beforeRouteLeave?(
            to: Route,
            from: Route,
            next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
        ): void;

        beforeRouteUpdate?(
            to: Route,
            from: Route,
            next: (to?: RawLocation | false | ((vm: Vue) => void)) => void
        ): void;
    }
}

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate"
]);

const title = process.env.VUE_APP_TITLE;

router.beforeEach((to, from, next) => {
    document.title = to.meta?.title ? `${title} - ${to.meta.title}` : title;

    if (!to.meta?.allowAnonymous && !to.meta?.allowOnlyAnonymous) {
        if (
            !isUserAuthorized() ||
            (to.meta?.allowedRoles && !isUserInRoles(to.meta.allowedRoles))
        ) {
            next(Routes.Login + "?redirecturl=" + to.path);
        } else next();
    } else {
        if (to.meta?.allowOnlyAnonymous) {
            if (isUserAuthorized()) next(Routes.Cases);
            else next();
        } else next();
    }
});
