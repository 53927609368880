
import { User } from "@/models/User";
import Vue from "vue";
import { Component, Prop, PropSync } from "vue-property-decorator";
import { getUserFullName, checkIsUserDisabled } from "@/services/userUtils";

@Component({
    methods: {
        getUserFullName,
        checkIsUserDisabled
    }
})
export default class UserAutocomplete extends Vue {
    @PropSync("userId", { required: true })
    protected userIdSync!: number | null;

    @Prop({ type: Array, required: true })
    protected users!: User[];

    @Prop({ type: String, default: "" })
    protected label!: string;

    @Prop({ type: Array, default: () => [] })
    protected rules!: (() => boolean | string)[];

    @Prop({ type: Boolean, default: true })
    protected outlined!: boolean;

    @Prop({ type: Boolean, default: false })
    protected hideDetails!: boolean;

    @Prop({ type: Boolean, default: false })
    protected disabled!: boolean;
}
