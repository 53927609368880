import { User } from "@/models/User";
import { GetByPageRequest } from "@/services/requestUtils";
import api from ".";

async function getUsersByPage(payload: GetByPageRequest): Promise<{
    users: User[];
    totalCount: number;
}> {
    const response = await api.post("/users/page", payload);
    return response.data;
}

async function getUsers(): Promise<User[]> {
    const response = await api.get("/users");
    return response.data;
}

async function getUsersByIds(ids: Array<number>): Promise<User[]> {
    const response = await api.get(
        `/users/byIds?${ids.map((n) => `ids=${n}`).join("&")}`
    );
    return response.data;
}

async function getUserDetails(userId: number): Promise<User> {
    const response = await api.get("/user/" + userId);
    return response.data;
}

async function updateUser(user: Partial<User>): Promise<void> {
    const response = await api.post("/user/" + user.id, user);
    return response.data;
}

async function createUser(user: Partial<User>): Promise<void> {
    const response = await api.post("/users/", user);
    return response.data;
}

async function getTwoFactorRecoveryCode(userId: number): Promise<string> {
    const response = await api.get(`/user/${userId}/code`);
    if (response.status != 200) throw response.data;
    return response.data;
}

export default {
    getUsersByPage,
    getUsersByIds,
    getUsers,
    getUserDetails,
    updateUser,
    createUser,
    getTwoFactorRecoveryCode
};
